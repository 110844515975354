<template>
    <section id="signup">
        <h3>Sign up</h3>
        <div class="row justify-content-center">
            <div class="col-md-12">
                <p v-if="errors['other'].length">
                    <b>Please correct the following error(s):</b>
                    <ul>
                        <li v-for="error in errors['other']" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>

                <form method="post" action="" @submit="formSubmit">
                    <table>
                        <!-- Username -->
                        <tr>
                            <td><label for="id_username">Username:</label></td>
                            <td><input type="text" name="username" v-model="username" autofocus="" autocapitalize="none" autocomplete="username" maxlength="150" required="" id="id_username"></td>
                            <td>
                                <span class="fa"          v-if="fieldState['username'] === FieldState.Empty"  >                          </span>
                                <span class="fa fa-check" v-if="fieldState['username'] === FieldState.Valid"  >                          </span>
                                <span class="fa fa-times" v-if="fieldState['username'] === FieldState.Invalid"> {{ errors['username'] }} </span>
                            </td>
                        </tr>

                        <!-- Username requirements -->
                        <tr>
                            <td colspan="2" class="form-text">
                                <ul>
                                    <li>150 characters or fewer. Letters, digits and ./+/-/_ only.</li>
                                </ul>
                            </td>
                        </tr>

                        <!-- Email -->
                        <tr>
                            <td><label for="id_email">Email:</label></td>
                            <td><input type="email" name="email" v-model="email" autofocus="" autocapitalize="none" autocomplete="email" maxlength="150" required="" id="id_email"></td>
                            <td>
                                <span class="fa"          v-if="fieldState['email'] === FieldState.Empty"  >                       </span>
                                <span class="fa fa-check" v-if="fieldState['email'] === FieldState.Valid"  >                       </span>
                                <span class="fa fa-times" v-if="fieldState['email'] === FieldState.Invalid"> {{ errors['email'] }} </span>
                            </td>
                        </tr>

                        <!-- Password -->
                        <tr>
                            <td><label for="id_password1">Password:</label></td>
                            <td><input type="password" name="password1" v-model="password1" autocomplete="new-password" required="" id="id_password1"></td>
                            <td>
                                <span class="fa"          v-if="fieldState['password1'] === FieldState.Empty"  >                           </span>
                                <span class="fa fa-check" v-if="fieldState['password1'] === FieldState.Valid"  >                           </span>
                                <span class="fa fa-times" v-if="fieldState['password1'] === FieldState.Invalid"> {{ errors['password1'] }} </span>
                            </td>
                        </tr>

                        <!-- Password Confirmation -->
                        <tr>
                            <td><label for="id_password2">Password confirmation:</label></td>
                            <td><input type="password" name="password2" v-model="password2" autocomplete="new-password" required="" id="id_password2"></td>
                            <td>
                                <span class="fa"          v-if="fieldState['password2'] === FieldState.Empty"  >                           </span>
                                <span class="fa fa-check" v-if="fieldState['password2'] === FieldState.Valid"  >                           </span>
                                <span class="fa fa-times" v-if="fieldState['password2'] === FieldState.Invalid"> {{ errors['password2'] }} </span>
                            </td>
                        </tr>

                        <!-- Password rules -->
                        <tr>
                            <td colspan="2" class="form-text">
                                <ul>
                                    <li>Your password must contain at least 8 characters.</li>
                                    <li>Your password cannot be too similar to your other personal information.</li>
                                    <li>Your password cannot be a commonly used password.</li>
                                    <li>Your password cannot be entirely numeric.</li>
                                </ul>
                            </td>
                        </tr>

                        <!-- Signup button -->
                        <tr>
                            <td colspan="2">
                                <input type="submit" class="btn btn-primary btn-lg" value="Signup" />
                            </td>
                        </tr>
                    </table>
                </form>

                <p>
                    If you already have an account <router-link :to="{ name:'login' }"><strong>login</strong></router-link> instead.
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import { api } from '../../axios'
import { FieldState } from '../../store/constants';
// Import components
// import [component name] from './[component name].vue';

// Export app
export default {
    name: 'SignupMain',

    // Set components
    components: {
        // [component name],
    },

    // Declare data used in login form
    data() {
        return {
            FieldState: FieldState,
            errors: {
                "username" : null,
                "email"    : null,
                "password1": null,
                "password2": null,
                "other"    : [],
            },
            fieldState: {
                "username" : FieldState.Empty,
                "email"    : FieldState.Empty,
                "password1": FieldState.Empty,
                "password2": FieldState.Empty,
            },
            
            username : null,
            email    : null,
            password1: null,
            password2: null,
        }
    },

    // Define function that watch the username, email, and passwords, such that we can update the status
    // messages when the user changes those fields
    watch: {
        /**
         * Watch the username field, and check whether it is valid
         */
        username: function(val) {
            // Test if field is empty
            if (val === "") {
                this.fieldState["username"] = FieldState.Empty;
                this.errors["username"] = "";
                return;
            }

            // Test if input has length >= 4
            if (val.length < 4) {
                this.fieldState["username"] = FieldState.Invalid;
                this.errors["username"] = "Username too short";
                return;
            }

            // Test whether field contains invalid characters
            if (!RegExp("^[0-9a-zA-Z\\.\\+\\_\\-]+$").test(val)) {
                this.fieldState["username"] = FieldState.Invalid;
                this.errors["username"] = "Username contains invalid characters";
                return;
            }

            // Field must be valid now
            this.fieldState["username"] = FieldState.Valid;
            this.errors["username"] = "";
        },

        /**
         * Watch the email field, and check whether it is valid
         */
        email: function(val) {
            // Test if field is empty
            if (val === "") {
                this.fieldState["email"] = FieldState.Empty;
                this.errors["email"] = "";
                return;
            }

            // Test if input passed email regex
            if (!this.validEmail(val)) {
                this.fieldState["email"] = FieldState.Invalid;
                this.errors["email"] = "Invalid email address";
                return;
            }

            // Test whether email domain is in known emails list, and if not compute domain with lowest 
            // Levenshtein distance TODO not implemented yet
            /*if (!RegExp("^[0-9a-zA-Z\@\.\+\_\-]+$").test(val)) {
                this.fieldState["username"] = FieldState.Invalid;
                this.errors["username"] = "Username contains invalid characters";
                return;
            }*/

            // Field must be valid now
            this.fieldState["email"] = FieldState.Valid;
            this.errors["email"] = "";
        },

        /**
         * Watch the first password field, and check whether it is valid
         */
        password1: function(val) {
            // Test if field is empty
            if (val === "") {
                this.fieldState["password1"] = FieldState.Empty;
                this.errors["password1"] = "";
                return;
            }

            // Test if input has length >= 8
            if (val.length < 8) {
                this.fieldState["password1"] = FieldState.Invalid;
                this.errors["password1"] = "Password too short";
                return;
            }

            // Test whether field is not entirely numeric
            if (RegExp("^[0-9]+$").test(val)) {
                this.fieldState["password1"] = FieldState.Invalid;
                this.errors["password1"] = "Password cannot be entirely numeric";
                return;
            }

            // Field must be valid now
            this.fieldState["password1"] = FieldState.Valid;
            this.errors["password1"] = "";
        },

        /**
         * Watch the password confirmation field, and check whether it is valid
         */
        password2: function(val) {
            // Test if field is empty
            if (val === "") {
                this.fieldState["password2"] = FieldState.Empty;
                this.errors["password2"] = "";
                return;
            }

            // Test if input is same to first password
            if (val !== this.password1) {
                this.fieldState["password2"] = FieldState.Invalid;
                this.errors["password2"] = "Passwords are different";
                return;
            }

            // Field must be valid now
            this.fieldState["password2"] = FieldState.Valid;
            this.errors["password2"] = "";
        },
    },

    methods: {
        /** 
         * Email validation regex pattern, copy-pasta'd from https://v2.vuejs.org/v2/cookbook/form-validation.html#Using-Custom-Validation
         */
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        /**
         * Prevent the default action of the form, and send a POST request to sign up
         * the user
         */
        async formSubmit(e) {
            e.preventDefault();

            this.errors['other'] = [];

            // Check if username field is valid
            if (this.fieldState['username'] !== FieldState.Valid) {
                this.errors['other'].push("Please fix your username before submitting the form");
            }

            // Check if email field is valid
            if (this.fieldState['email'] !== FieldState.Valid) {
                this.errors['other'].push("Please fix your email before submitting the form");
            }

            // Check if password field is valid
            if (this.fieldState['password1'] !== FieldState.Valid) {
                this.errors['other'].push("Please fix your password before submitting the form");
            }

            // Check if password confirmation field is valid
            if (this.fieldState['password2'] !== FieldState.Valid) {
                this.errors['other'].push("Please fix your password confirmation before submitting the form");
            }

            // If we have errors, return and let the user fix them
            if (this.errors['other'].length > 0) {
                console.log("We have errors, namely:", this.errors['other'])
                return;
            }

            // If we don't have errors, attempt to send a POST request for signup
            api.post("/api/homepage/authentication/signup", new URLSearchParams({
                username : this.username,
                email    : this.email,
                password1: this.password1,
                password2: this.password2,
            })).then((response) => {
                // Check the response status
                // If there is an error, display the error and exit
                if (response.data.status === "failed") {
                    this.errors["other"] = response.data.errors;
                    return;
                }
                
                // Otherwise, redirect to the signup complete page
                this.$router.push({ name: 'signup_complete' });
            }).catch((error) => {
                console.log(error);
            });
        }
    },

    // Change height to auto
    mounted(){
        document.body.style.height = "auto";
    },

    computed: {
        // No computed attributes
    }
}
</script>



<style scoped media="screen">
  .fa-check{
    color:green;
  }
  .fa-exclamation{
    color:orange;
  }
  .fa-times{
    color:red;
  }
  input{
      width: 100%;
  }
  .fa{
      padding-left: 8px;
      padding-right: 8px;
  }
</style>
