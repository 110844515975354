<template>
    <section id="reset">
        <h3>Reset Password</h3>
        <div class="row justify-content-center">
            <!-- Show form if request has not been completed successfully yet-->
            <div class="col-md-12" v-if="!requestSuccessful">

                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul>
                        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>

                <form method="post" action="" @submit="formSubmit">
                    <p>
                        <table>
                            <tr>
                                <td><label for="id_email">Email:</label></td>
                                <td><input type="email" name="email" v-model="email" autofocus="" autocapitalize="none" autocomplete="email" maxlength="254" required="" id="id_email"></td>
                            </tr>
                        </table>
                    </p>
                    <p>
                        <button type="submit" class="btn btn-primary btn-lg" v-if="!loadingRequest">Reset password</button>
                        <button type="submit" class="btn btn-primary btn-lg" disabled="" v-else><span class="spinner-border spinner-border-sm"></span></button>
                    </p>
                </form>
            </div>

            <!-- If request has been completed successfully, show success message -->
            <div class="col-md-12" v-else>
                <p>We've emailed you instructions for setting your password from the <a href="#">ths.eemcs@gmail.com</a> email address. If they haven't arrived in a few minutes, check your spam folder.</p>
            </div>
        </div>
    </section>
</template>

<script>
import { api } from '../../axios'
// Import components
// import [component name] from './[component name].vue';

// Export app
export default {
    name: 'PasswordChangeRequest',

    // Set components
    components: {
        // [component name],
    },

    // Declare data used in login form
    data() {
        return {
            errors: [],
            email: null,
            requestSuccessful: false,
            loadingRequest: false
        }
    },

    methods: {
        /**
         * Prevent the default action of the form, and send a POST request to authenticate
         * the user
         */
        async formSubmit(e) {
            e.preventDefault();

            this.errors = [];

            if (!this.email) {
                this.errors.push("Please fill in an emailaddress.");
            }

            // If we have errors, return and let the user fix them
            if (this.errors.length) {
                return;
            }

            // Set loading request status to true
            this.loadingRequest = true;

            // If we don't have errors, attempt to send a POST request for password change
            api.post("/api/homepage/authentication/change", new URLSearchParams({
                email: this.email
            })).then((response) => {
                // Set loading request status to false
                this.loadingRequest = false;

                // Check the response status
                // If there is an error, display the error and exit
                if (response.data.status === "failed") {
                    this.errors = response.data.errors;
                    return;
                }

                // If our request was successful, display the success message
                this.requestSuccessful = true;
            }).catch((error) => {
                console.log(error);
            });
        }
    },

    // Change height to auto
    mounted(){
        document.body.style.height = "auto";
    },

    computed: {
        // No computed attributes
    }
}
</script>


