import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

// Import CSS
import '../src/assets/css/utwente.css'

createApp(App)
    .use(store)
    .use(router)
    .mount('#app');