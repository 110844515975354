<template lang="html">
    <div class="row justify-content-center">
        <div class="col-lg-10 col-md-12">
            <!-- Header -->
            <h3 class="no-margin">Filter</h3>
            <hr>

            <!-- Search challenges -->
            <div class="row">
                <input id="searchChallenges" type="text" class="form-control filter-search" name="search" placeholder="Search for challenges" v-on:keyup="search($event)">
            </div>

            <!-- Sort by -->
            <div class="row form-group margin-top-sm">
                <label class="form-select-label filter-label" for="sortSelect">SORT BY</label>
                <select class="form-select form-select-lg filter-select" name="sortSelect" @change="sort($event)">
                    <!-- List all sort options -->
                    <option
                        v-for     = "option, value in allSortOptions"
                        :key      = "option"
                        :value    = "value"
                        :selected = "this.$store.state.challengesSort == option ? true : null"
                    >
                        {{ option }}
                    </option>
                </select>
            </div>

            <!-- Hide solved challenges -->
            <div class="row form-check form-switch margin-top-sm">
                <label class="form-check-label filter-label" for="hideSolved">HIDE SOLVED</label>
                <input id="filterSolved" class="form-check-input" type="checkbox" name="hideSolved" value="hideSolved" @change="toggleHideSolved($event)">
            </div>
        </div>

    </div>
</template>

<script>
import { sortOptions } from '../../store/constants'

export default {
    name: 'ChallengeFilter',

    data() {
        return {
            allSortOptions: sortOptions,
        }
    },

    methods: {
        /**
         * Search for a value using the target string.
         */
        search(event) {
            // Set search to new value
            this.$store.dispatch('searchChallenges', event.target.value);
        },

        /**
         * Sort challenges by option.
         */
        sort(event) {
            // Set sort to new value
            this.$store.commit(
                'setChallengesSort',
                event.target.value,
            );
        },

        /**
         * Toggle hiding the solved values
         */
        toggleHideSolved(event) {
            // Set show completed to opposite of checked hide
            this.$store.commit(
                'setChallengesShowCompleted',
                !event.target.checked,
            );
        },
    },
}
</script>

<style lang="css" scoped>
    .no-margin {
        margin: 0px;
    }

    .margin-top-sm {
        margin-top: 10px;
    }

    .filter-label {
        font-weight: 700;
        font-size: 16px;
    }

    .filter-search {
        font-size: 16px;
        width: 100%;
    }

    .filter-select {
        display: inline-block;
        font-size:16px;
    }
</style>
