<template>
    <!-- Patches -->
    <section id="patches">
        <h1 style="margin-bottom:30px;">Patch Log</h1>
        <!-- Patchlog explanation -->
        <p>
            Below you will find a list of patches made to the website on different dates.
            Note that these patches do not include added/removed challenges.
            Instead, these patches are related to the frontend and backend of the website.
            If you noticed a <del>feature</del> bug in the website or are not satisfied with one of the patches we made, please let us know, we appreciate all feedback.
        </p>

        <!-- Table with patches -->
        <PatchlogList />
    </section>
</template>

<script>

// Import components
import PatchlogList from './PatchlogList.vue';

// Export app
export default {
    name: 'PatchlogMain',

    // Set components
    components: {
        PatchlogList,
    },

    methods: {
        // No Methods
    },

    // Change height to auto
    mounted(){
        document.body.style.height = "auto";
    },
}
</script>


