<template>
    <div class="container-fluid">
        <!-- Header -->
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <h1>Signup Complete</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <p>
                    Thank you for signing up, we have sent you an email to confirm your registration. If the email did not arrive within a few minutes, please check your spam folder.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
// Import components
// import [component name] from './[component name].vue';

// Export app
export default {
  name: 'SignupComplete',

  // Set components
  components: {
    // [component name],
},

  methods: {
      // No methods yet
  },

  // Change height to auto
  mounted(){
      document.body.style.height = "auto";
  },
}
</script>


