<template>
    <div class="container-fluid">
        <!-- Header -->
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <h1>Highscores</h1>
            </div>
        </div>

        <!-- Highscores -->
        <div class="row justify-content-center">
            <!-- Left sidebar -->
            <div id="highscoreFilter" class="col-lg-2 sidebarStatic sidebar">
                <HighscoreFilter></HighscoreFilter>
            </div>

            <!-- Main content -->
            <div class="col-lg-12 px-0">

                <!-- Highscores -->
                <div class="row margin-top-medium">
                    <HighscoreList></HighscoreList>
                </div>
            </div>
        </div>

        <!-- Images source -->
        <div class="row">
            <div class="col-md-12 footer-color">
                Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
            </div>
        </div>
    </div>
</template>

<script>
// Import compontents
import HighscoreFilter from './HighscoreFilter.vue'
import HighscoreList   from './HighscoreList.vue'

// Export app
export default {
  name: 'HighscoreMain',

  // Set components
  components: {
      HighscoreFilter,
      HighscoreList,
  },

  methods: {
      /**
       * Get scroll position of current window.
       */
      onScroll(){
          // Get challenge filter element
          let filter = document.getElementById('highscoreFilter');

          if (window.pageYOffset >= 173){
              filter.classList.remove('sidebarStatic');
              filter.classList.add   ('sidebarDynamic');
          } else {
              filter.classList.remove('sidebarDynamic');
              filter.classList.add   ('sidebarStatic');
          }
      },
  },

  // Change height to auto
  mounted(){
      document.body.style.height = "auto";

      // Add event listener to scroll
      window.addEventListener("scroll", this.onScroll)
  },
}
</script>

<style>
    .footer-color {
        color: #888;
    }

    .margin-top-medium {
        margin-top: 50px;
    }

    @media only screen
    and (min-width : 992px) {
        .sidebarStatic {
            position: absolute;
            top: 223px;
            left: 0;
        }

        .sidebarDynamic {
            position: fixed; /* Make the sidebar immovable*/
            z-index: 1; /* Side bar stays at the top */
            top: 50px;
            left: 0;
            overflow-x: hidden; /* restricting horizontal scroll */
        }
    }

    @media only screen
    and (max-width : 992px) {
        .sidebar {
            margin-top: 20px;
        }
    }
</style>
