<template>
    <!-- Section Heading -->
    <h3 class="header">Ranking</h3>
    <hr>

    <!-- Table -->
    <div>
        <table class="table table-hover">
            <tbody>
                <tr>
                    <td><h3>Rank</h3></td>
                    <td><h3 class="data">{{ rank }}</h3></td>
                </tr>
                <tr>
                    <td><h3>Score</h3></td>
                    <td><h3 class="data">{{ score }}</h3></td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Badge(s) -->
    <div class="header">
        <img v-if="rank == 1" src="../../assets/medal_gold.png"   alt="Gold Medal">
        <img v-if="rank == 2" src="../../assets/medal_silver.png" alt="Silver Medal">
        <img v-if="rank == 3" src="../../assets/medal_bronze.png" alt="Bronze Medal">
    </div>
</template>

<script>
// Import methods
import { mapActions } from 'vuex'

// Export app
export default {
  name: 'PlayerScore',

  methods: {
      /**
       * Map actions from store
       */
      ...mapActions([
          'loadUser',
      ])
  },

  computed: {
      /**
       * Compute the user rank from the stored object.
       */
      rank() {
          return this.$store.state.user.rank;
      },

      /**
       * Compute the user score as the sum of points from completed challenges.
       */
      score() {
          // Initialise score
          var score = 0;

          // Loop over all completed challenge IDs
          for (var completed of this.$store.state.completed){
              // Check if completed is in challenges
              if (completed in this.$store.state.challenges)
                  // Increment score by points of completed challenge
                  score += this.$store.state.challenges[completed].points;
          }

          // Return score
          return score;
      },
  },

  beforeMount() {
      // Load the user stats before mounting
      this.loadUser();
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    img {
        width: 50px;
        height: 50px;
    }
    .header {
        text-align: center;
    }
    .data {
        text-align: right;
    }

    @media only screen
    and (min-width : 992px) {
        h3 {
            margin-top: 0px;
        }
    }

    @media only screen
    and (max-width : 992px) {
        h3 {
            margin-top: 20px;
        }
    }
</style>
