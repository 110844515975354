<template>
  <div class="col-lg-12">
    <div class="wrapper">
      <h1>{{ username }}</h1>
      <h2>
        <img v-if="rank == 1"       src="../../assets/medal_gold.png"   alt="Gold Medal">
        <img v-if="rank == 2"       src="../../assets/medal_silver.png" alt="Silver Medal">
        <img v-if="rank == 3"       src="../../assets/medal_bronze.png" alt="Bronze Medal">
        <img v-if="rank == 'Staff'" src="../../assets/logo_ths.svg"     alt="THS logo">
        {{ rankString }}
      </h2>
      <h2>{{ totalPoints }} points</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserHead',

  props: [
    'userId'
  ],

  methods: {
  },

  computed: {
    /**
     * Obtains the username of the presented user.
     */
    username() {
      return this.userId in this.$store.state.userProfiles
        ? this.$store.state.userProfiles[this.userId].username
        : "";
    },

    /**
     * Obtains the total number of points the user has accumulated.
     */
    totalPoints() {
      return this.userId in this.$store.state.userProfiles
        ? this.$store.state.userProfiles[this.userId].total_points
        : 0;
    },

    /** 
     * Obtains the rank the user is currently at in the highscore list.
     */
    rank() {
      return this.userId in this.$store.state.userProfiles
        ? this.$store.state.userProfiles[this.userId].rank
        : undefined;
    },

    /**
     * Formats the user's rank as a human readable string.
     */
    rankString() {
      if (!(this.userId in this.$store.state.userProfiles))
        return "N/A";

      const rank = this.$store.state.userProfiles[this.userId].rank;
      if (rank == "Staff" || rank == "N/A")
        return rank;
      if (rank == undefined) {
        return "N/A";
      }

      const rankNumber = parseInt(rank);
      let suffix = '';

      switch (rankNumber % 100) {
        case 11:
        case 12:
        case 13:
          suffix = 'th';
          break;
          
        default:
          switch (rankNumber % 10) {
            case 1:
              suffix = 'st';
              break;

            case 2:
              suffix = 'nd';
              break;

            case 3:
              suffix = 'rd';
              break;

            default:
              suffix = 'th';
              break;
          }
          break;
      }

      return `${rankNumber}${suffix} place`;
    }
  }
}
</script>

<style scoped>

div.wrapper {
  text-align: center;
}

div.wrapper > h1 {
  margin-top: 40px;
  margin-bottom: 40px;
}

div.wrapper > h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

img {
  width: 40px;
}
</style>
