<template>
    <div class="row justify-content-center">
        <div class="col-md-12">
            <h2>CTF practice websites</h2>
            Besides the challenges from the THS, the websites listed below also provide great challenges ranging from beginner to expert level.
            During our training sessions, we often pick challenges from these websites to practice.
            <table class="table table-hover table-sm">
                <thead>
                    <tr>
                        <th>Website</th>
                        <th>Type</th>
                        <th>Difficulty</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><a href="https://capturetheflag.withgoogle.com/" target="_blank">capturetheflag.withgoogle.com <i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td>All</td>
                        <td>All</td>
                    </tr>
                    <tr>
                        <td><a href="https://cryptohack.org/" target="_blank">cryptohack.org<i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td>Crypto</td>
                        <td>All</td>
                    </tr>
                    <tr>
                        <td><a href="https://www.hackthebox.eu/" target="_blank">hackthebox.eu<i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td>All</td>
                        <td>All</td>
                    </tr>
                    <tr>
                        <td><a href="https://picoctf.org/" target="_blank">picoctf.org <i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td>All</td>
                        <td>Easy-Medium</td>
                    </tr>
                    <tr>
                        <td><a href="https://tryhackme.com/" target="_blank">tryhackme.com<i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td>Pwn</td>
                        <td>All</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
// Import components
// import [component name] from './[component name].vue'

// Export app
export default {
  name: 'ResourcePracticeWebsites',

  // Set components
  components: {
    //   [component name],
  },

  methods: {
      // No methods yet
  },

  mounted(){
  },
}
</script>

<style>

</style>