<template lang="html">
    <!-- Only show category if we have a challenge to show -->
    <div type="button" v-if="visibleChallengesLength > 0">

        <!-- Category header -->
        <h2 class="category-header" v-on:click="this.toggleCategoryExpand(this.category)">
            <span class="category-title">
                <!-- Category icon -->
                <img :src="icon_path" :alt="'Icon ' + category">
                <!-- Category name -->
                {{ category.toUpperCase() }}
                <i v-if="this.expand" class="fa fa-chevron-up"></i>
                <i v-else class="fa fa-chevron-down"></i>
            </span>
            <span class="category-solved">
                {{ completedChallenges }}/{{ challengesLength }} Solved
            </span>
        </h2>
        <hr>

        <!-- Category challenges -->
        <div v-if="this.expand" class="row">
            <!-- Loop over all challenges -->
            <div v-for="challenge of listChallenges(category)" :key="challenge">
                <!-- Show challenge -->
                <ChallengeObject :id="challenge.id"></ChallengeObject>
            </div>
        </div>
    </div>

</template>

<script>
// Import methods
import { mapActions  } from 'vuex'

// Import constants
import { sortOptions } from '../../store/constants'

// Import components
import ChallengeObject from './ChallengeObject.vue'

export default {
    name: 'ChallengeCategory',

    props: [
        'category'
    ],

    // Set components
    components: {
        ChallengeObject,
    },

    methods: {
        /**
         * Get all challenges from a specific category.
         * If no category is provided, return all challenges.
         */
        challengeCategory(category){
            // Return all visible challenges
            return Object.values(this.$store.state.challenges).filter(
                challenge => category === undefined | challenge.category === category
            );
        },

        /**
         * List all challenges of a category in the correct order.
         * If no category is provided, return all challenges.
         */
        listChallenges(category) {
            // Get all challenges
            var challenges = this.visibleChallenges(category);

            // Sort challenges in given order
            challenges = this.sortChallenges(challenges, this.sortOrder);

            // Return challenges
            return challenges;
        },

        /**
         * Sort all challenges in a given correct order.
         */
        sortChallenges(challenges, order) {
            // Sort alphabetically first
            challenges = challenges.sort((a, b) => a.title.localeCompare(b.title));

            // Sort by option if required
            switch (order) {
                case sortOptions.DifficultyAscending:
                    challenges = challenges.sort((a, b) => b.n_solves - a.n_solves);
                    break;
                case sortOptions.DifficultyDescending:
                    challenges = challenges.sort((a, b) => a.n_solves - b.n_solves);
                    break;
                case sortOptions.AlphabeticalDescending:
                    challenges = challenges.sort((a, b) => b.title.localeCompare(a.title));
                    break;
            }

            // Sort solved challenges last - removed haha suck it Yoep
            // challenges.sort(
            //     (a, b) =>
            //     this.$store.state.completed.has(a.id) -
            //     this.$store.state.completed.has(b.id)
            // );

            // Return challenges
            return challenges;
        },

        /**
         * Get all visible challenges of a category.
         * If no category is provided, return all visible challenges.
         */
        visibleChallenges(category) {
            // Get all visible challenges
            var challenges = this.challengeCategory(category).filter(
                challenge => challenge.show
            );

            // Filter completed challenges if required
            if (!this.showCompleted){
                challenges = challenges.filter(
                    challenge => !this.completed.has(challenge.id)
                );
            }

            // Return challenges
            return challenges;
        },

        /**
         * Map actions from central store
         */
        ...mapActions([
            'toggleCategoryExpand',
        ]),
    },

    computed: {
        /**
         * Get total number of challenges that should be shown.
         */
        challengesLength() {
            return this.challengeCategory(this.category).length;
        },

        /**
         * Get filter whether we should show completed challenges
         */
        completed() {
            return this.$store.state.completed;
        },

        /**
         * Get total number of challenges completed in this category.
         */
        completedChallenges() {
            // Initialise number of completed challenges
            var completed = 0;

            // Loop over challenges
            for (var challenge of this.challengeCategory(this.category)){
                if (this.$store.state.completed.has(challenge.id))
                    completed++;
            }

            // Return number of completed challenges
            return completed;
        },

        /**
         * Check if category is expanded
         */
        expand() {
            return this.$store.state.categories[this.category].expand
        },

        /**
         * Return the icon path for the given category
         */
        icon_path() {
            return this.$store.state.categories[this.category].icon_path
        },

        /**
         * Get filter whether we should show completed challenges
         */
        showCompleted() {
            return this.$store.state.challengesShowCompleted;
        },

        /**
         * Get the sort order of challenges
         */
        sortOrder() {
            return this.$store.state.challengesSort;
        },

        /**
         * Get total number of challenges that should be shown.
         */
        visibleChallengesLength() {
            return this.visibleChallenges(this.category).length;
        },
    },
}
</script>

<style lang="css" scoped>
    img {
        width: 50px;
        margin-right: 10px;
    }

    .category-header {
        margin-top: 30px;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .category-title {
        display: inline-block;
    }

    .category-solved {
        display: inline-block;
        padding-top: 12px;
        font-size:18px;
    }
</style>
