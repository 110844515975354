<template>
    <div type="button">
        <!-- Category header -->
        <h2 class="custom-challenge-header" v-on:click="this.expand = !this.expand">
            <span class="custom-challenge-title">
                <img src="../../assets/icon_idea.png" alt="Icon custom challenge">
                CREATE YOUR OWN CHALLENGE!
                <i v-if="this.expand" class="fa fa-chevron-up"></i>
                <i v-else class="fa fa-chevron-down"></i>
            </span>
        </h2>
        <hr>

        <!-- Create your own challenge explanation -->
        <div v-if="this.expand" class="card card-body">
            <!-- Challenge meta data -->
            <div class="row">
                <!-- Authors -->
                <div class="col-md-6 challenge-authors">
                    Author(s): You~
                </div>
            </div>

            <div class="row">
                <p>
                    Can't you get enough of the challenges there on the THS website?
                    Are you pent-up with creativity and have no way to let it out?
                    Do you want to plague the other THS members with your own special-crafted challenges?
                    Now you have the possibility to create your own!
                    Here at the THS we have a passion for solving CTF challenges, but creating them is a horse of a different colour.
                    Do you have what it takes?
                    Below you can download the template we use to create challenges for the THS website.
                    Use the template to create your challenge, submit it, and be immortalized on the THS website!
                    All challenges will be hand-checked by the THS team to make sure they are solvable, scored correctly, and safe™ to execute.
                </p>
            </div>

            <!-- Downloads -->
            <div class="row">
                <h4>Downloads</h4>
                <div>
                    <ul>
                        <li><a href="/api/challenges/submit/template">template.zip</a></li>
                    </ul>

                </div>
            </div>

            <!-- Submission -->
            <form id="challengeSubmissionForm" class="" @submit="submitChallenge($event)">
                <div class="row">
                    <h4>Submission:</h4>
                    <div class="col-md-1">
                        Category:
                    </div>
                    <div class="col-md-1">
                        <select class="form-select form-select-lg" name="category">
                            <option value="crypto" selected>Crypto</option>
                            <option value="forensics">Forensics</option>
                            <option value="misc">Misc</option>
                            <option value="pwn">Pwn</option>
                            <option value="reversing">Reversing</option>
                            <option value="web">Web</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <input class="form-control" type="file" name="challenge">
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-lg challenge-submit-button" type="submit" name="submit"><b>Submit Challenge</b></button>
                    </div>
                </div>
            </form>

            <!-- Show messages -->
            <div v-if="message_success !== null" class="row">
                <div class="alert alert-success alert-dismissible col-md-12" role="alert">
                  {{ message_success }}
                  <button type="button" class="btn-close margin-right-sm" v-on:click="message_success = null"></button>
                </div>
            </div>
            <div v-if="message_info !== null" class="row">
                <div class="alert alert-warning alert-dismissible col-md-12" role="alert">
                  {{ message_info }}
                  <button type="button" class="btn-close margin-right-sm" v-on:click="message_info = null"></button>
                </div>
            </div>
            <div v-if="message_error !== null" class="row">
                <div class="alert alert-danger alert-dismissible col-md-12" role="alert">
                  <strong>Error:</strong> {{ message_error }}
                  <button type="button" class="btn-close margin-top-xs margin-right-sm" v-on:click="message_error = null"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Imports
import { api } from '../../axios'

export default {
  name: 'ChallengeSubmission',

  data() {
      return {
          expand: false,
          message_success: null,
          message_info   : null,
          message_error  : null,
      }
  },

  methods: {
      /**
       * Get cookies as an object.
       */
      getCookies(){
          // Initialise result
          var result = {};

          // Get cookies
          var cookies = decodeURIComponent(document.cookie).split(';');

          // Loop over cookies
          for (var cookie of cookies){
              // Get cookie as pair
              cookie = cookie.trim().split('=');
              // Set result
              result[cookie[0]] = cookie[1];
          }

          // Return result
          return result;
      },

      /**
       * Submit a challenge to the webserver
       */
        async submitChallenge(){
            // Prevent default submission
            event.preventDefault();

            // Submit challenge
            const response = await api.post('/api/challenges/submit/challenge',
                new FormData(document.getElementById("challengeSubmissionForm"))
            )

            // Add response
            if (response.data.success.length){
                this.message_success = response.data.success.join(' ');
            }
            if (response.data.warning.length){
                this.message_error = response.data.warning.join(' ');
            }
        },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    img {
        width: 50px;
    }

    .challenge-authors {
        color: #888;
    }

    .challenge-submit-button {
        background-color: #800080;
        margin-bottom: 10px;
        color: white;
        text-align: center;
        width: 100%;
    }

    .custom-challenge-header {
        margin-top: 30px;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .custom-challenge-title {
        display: inline-block;
    }
</style>
