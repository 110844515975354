<template>
    <div class="container-fluid">
        <!-- Header -->
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <h1>Writeups</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                TODO
            </div>
        </div>
    </div>
</template>

<script>
// Import components
// import [component name] from './[component name].vue';

// Export app
export default {
  name: 'WriteupMain',

  // Set components
  components: {
    // [component name],
},

  methods: {
      // No methods yet
  },

  // Change height to auto
  mounted(){
      document.body.style.height = "auto";
  },
}
</script>


