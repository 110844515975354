<template>
  <div>
     <h1>404 Page not found</h1>
  </div>
</template>

<script>

// Export app
export default {
  name: 'ErrorMain',
}
</script>

<style>

</style>
