<template>
    <!-- Highscores -->
    <table class="col-md-12 table table-hover align-middle">
        <thead>
            <tr>
               <th width='40px'></th>
               <th>Rank</th>
               <th>Name</th>
               <th>Score</th>
            </tr>
        </thead>
        <tbody>
            <!-- Show empty in case of no highscores -->
            <tr v-if="!this.highscores.length">
                <td class="highscore-entry text-center" colspan=100%>No highscore entries found.</td>
            </tr>

            <!-- Loop over all highscores -->
            <tr :id="'highscore_' + highscore.id" :class="getClass(highscore)" v-for="highscore of this.highscores" :key="highscore" @click="this.navigateToUser(highscore.id)">
                <td>
                    <img v-if="highscore.rank == 1"       src="../../assets/medal_gold.png"   alt="Gold Medal">
                    <img v-if="highscore.rank == 2"       src="../../assets/medal_silver.png" alt="Silver Medal">
                    <img v-if="highscore.rank == 3"       src="../../assets/medal_bronze.png" alt="Bronze Medal">
                    <img v-if="highscore.rank == 'Staff'" src="../../assets/logo_ths.svg"     alt="THS logo">
                </td>
                <td>{{ highscore.rank }}</td>
                <td>{{ highscore.name }}</td>
                <td>{{ highscore.score }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
// Import methods
import { mapActions } from 'vuex'

// Export app
export default {
  name: 'HighscoreList',

  computed: {
      /**
       * Compute the highscores.
       */
      highscores() {
          // Initialise highscores
          var highscores = this.$store.state.highscore;

          // Sort highscores by score first, name second
          highscores = highscores.sort((a, b) => a.name.localeCompare(b.name));
          highscores = highscores.sort((a, b) => b.score - a.score);

          // Filter highscores if required
          if (!this.showStaff)
              highscores = highscores.filter(user => !user.staff)

          // Search highscores if required
          if (this.highscoreSearch)
              highscores = highscores.filter(
                  user => user.name.toLowerCase().includes(this.highscoreSearch)
              )


          // Return highscores
          return highscores
      },

      /**
       * Get highscore search value
       */
      highscoreSearch() {
          return this.$store.state.highscoreSearch;
      },

      /**
       * Check whether or not to show the staff entries in the highscores.
       */
      showStaff() {
          return this.$store.state.highscoreShowStaff;
      }
  },

  methods: {
      /**
       * Navigate to the provided user profile page.
       */
      navigateToUser(userId) {
        this.$router.push({ path: '/user/' + userId });
      },

      /**
       *
       */
      getClass(highscore){
          return {
              'highscore-entry': true,
              'table-success': highscore.current,
          }
      },

      /**
       * Map actions from central store
       */
      ...mapActions([
          'loadHighscore',
          'selectHighscore',
      ]),
  },

  async beforeMount() {
      // Load highscores
      await this.loadHighscore();

      // Get id of current user
      var id = NaN;
      for (var user of this.highscores){
          if (user.current){
              id = user.id;
              break;
          }
      }

      // Scroll to user
      setTimeout(function(){
          // Get challenge element
          var element = document.getElementById('highscore_' + id);
          // Scroll to element
          if (element) {
              element.scrollIntoView();
          }
      }, 50);
  },
}
</script>

<style>
    .center-text {
        text-align: center;
    }

    .highscore-entry {
        height: 52px;
        cursor: pointer;
    }
</style>

<style scoped>
    img {
        width: 40px;
    }
</style>
