<template>
  <div class="modal fade" ref="solversModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Solvers for {{ title }}</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th style="width: 100%">User</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(solver, index) in solvers" :key="solver">
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  <router-link :to="{ path: '/user/' + solver.id }">{{ solver.username }}</router-link>
                </td>
                <td>
                  {{ formatTimestamp(solver.timestamp) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-lg" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import methods
import { mapActions } from 'vuex'

// We're importing `Modal` specifically from the submodule as opposed to the main 
// bootstrap module, or else drop downs in the navbar break (only god knows why).
// See: https://stackoverflow.com/questions/68084742/dropdown-doesnt-work-after-modal-of-bootstrap-imported
import Modal from 'bootstrap/js/dist/modal';

// Export app
export default {
  name: 'ChallengeSolvesDialog',

  props: ["visible"],

  methods: {
    /**
     * Handles the hide.bs.modal event, by synchronizing it with the store.
     */
    onHide() {
      this.$store.state.solvers.visible = false;
    },

    /**
     * Formats the provided date string in a human-readable string.
     * @param timestamp 
     */
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date
        .toLocaleString('en-GB')
        .replace(" ", "\u00A0" /* non-breaking space */);
    },

    /**
     * Map actions from store
     */
    ...mapActions([
        'loadRecentSolves',
        'selectChallenge',
    ])
  },

  computed: {
    /**
     * Gets the title of the currently displayed challenge.
     */
    title() {
      return this.$store.state.solvers.title;
    },

    /**
     * Gets the users that have solved the challenge. 
     */
    solvers() {
      return this.$store.state.solvers.users;
    },
  },

  watch: {
    visible: {
      handler(newValue) {
        if (newValue) {
          this.modal.show();
        } else {
          this.modal.hide();
        }
      }
    }
  },

  mounted() {
    this.modal = new Modal(this.$refs.solversModal, {});
    this.$refs.solversModal.addEventListener('hide.bs.modal', this.onHide);
  },

  unmounted() {
    this.modal.hide();
  }

}

</script>

<style scoped>
.modal-title {
  margin-top: 0px;
}

.btn-lg {
  background-color: #800080;
  margin-bottom: 10px;
  color: white;
  text-align: center;
  font-size: 15px;
}
</style>