<template>
    <section id="login">
        <h3>Login</h3>
        <div class="row justify-content-center">
            <div class="col-md-12">
                <p v-if="this.$route.query.next">Please login to see this page.</p>

                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul>
                        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>

                <form method="post" action="" @submit="formSubmit">
                  <table>
                    <tr>
                      <td><label for="id_username">Username or email:</label></td>
                      <td><input type="text" name="username" v-model="username" autofocus="" autocapitalize="none" autocomplete="username" maxlength="150" required="" id="id_username"></td>
                    </tr>
                    <tr>
                      <td><label for="id_password">Password:</label></td>
                      <td><input type="password" name="password" v-model="password" autocomplete="current-password" required="" id="id_password"></td>
                    </tr>
                  </table>
                  <input type="submit" class="btn btn-primary btn-lg" value="login" />
                </form>

                <p>
                    <router-link :to="{ name: 'signup'}">New user?</router-link><br>
                    <router-link :to="{ name: 'password_reset'}">Lost password?</router-link>
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import { api } from '../../axios'// Import methods
import { mapActions } from 'vuex'

// Import components
// import [component name] from './[component name].vue';

// Export app
export default {
    name: 'LoginMain',

    // Set components
    components: {
        // [component name],
    },

    // Declare data used in login form
    data() {
        return {
            errors: [],
            username: null,
            password: null,
        }
    },

    methods: {
        /**
         * Map actions from store
         */
        ...mapActions([
            'loadUser',
        ]),

        /**
         * Prevent the default action of the form, and send a POST request to authenticate
         * the user
         */
        async formSubmit(e) {
            e.preventDefault();

            this.errors = [];

            if (!this.username) {
                this.errors.push("Please fill in a username.");
            }

            if (!this.password) {
                this.errors.push("Please fill in a password.");
            }

            // If we have errors, return and let the user fix them
            if (this.errors.length) {
                return;
            }

            // If we don't have errors, attempt to send a POST request for authentication
            api.post("/api/homepage/authentication/login", new URLSearchParams({
                username: this.username,
                password: this.password
            })).then((response) => {
                // Check the response status
                // If there is an error, display the error and exit
                if (response.data.status === "failed") {
                    this.errors = response.data.errors;
                    throw new Error("Authentication Failed!");
                }

                // Logged in succesfully! Load the user profile
                return this.loadUser();
            }).then(() => {
                // When the loadUser promise resolves, redirect the user to either
                // the next page or the homepage

                // If we specified a next, redirect there
                if (this.$route.query.next) {
                    this.$router.push(this.$route.query.next);
                    return;
                }
                
                // Otherwise, redirect to homepage
                this.$router.push({ name: 'homepage' });
            }).catch((error) => {
                console.log(error);
            });
        }
    },

    // Change height to auto
    mounted(){
        document.body.style.height = "auto";
    },
}
</script>


