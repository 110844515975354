<template>
    <div class="container-fluid">
        <section id="signup_confirmation">
            <!-- Header -->
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <h1>Signup Confirmation</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <p>
                        <!-- If the signup was confirmed successfully -->
                        <span v-if="this.confirmationStatus === this.responseOptions.Success">
                            Thank you for signing up, your account is now confirmed! <br />
                            <b>Click here to <router-link :to="{ name: 'login' }">login</router-link></b>
                        </span>

                        <!-- If the signup confirmation had an error -->
                        <span v-else-if="this.confirmationStatus === this.responseOptions.Failed">
                            Your token appears to be invalid, please try again later
                        </span>

                        <!-- If we are still waiting for a response from the server -->
                        <span v-else>
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">
                                    Loading...
                                </span>
                            </div>
                        </span>
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { api } from '../../axios';
import { responseOptions } from '../../store/constants';
// Import components
// import [component name] from './[component name].vue';

// Export app
export default {
    name: 'SignupConfirm',

    props: ['token'],

    // Declare data used in signup confirmation page
    data() {
        return {
            responseOptions: responseOptions,
            confirmationStatus: responseOptions.Pending,
        }
    },

    // Set components
    components: {
        // [component name],
    },

    methods: {
        // No methods yet
    },

    beforeMount() {
        // Send a POST request for signup verification
        api.post(`/api/homepage/authentication/confirm/${this.token}`)
        .then((response) => { 
            // Check the response status
            // If there is an error, update confirmationStatus to failed and exit
            if (response.data.status === "failed") {
                this.confirmationStatus = this.responseOptions.Failed;
                return;
            }

            // If our request was successful, update confirmationStatus to 
            // successful and exit
            this.confirmationStatus = this.responseOptions.Success;
        }).catch((error) => {
            console.log(error);
        });
    },

    // Change height to auto
    mounted(){
        document.body.style.height = "auto";
    },
}
</script>


