<template>
    <NavBar />
    <div class="container">
        <router-view></router-view>
    </div>
</template>

<script>
// Import styles
import 'bootstrap/dist/css/bootstrap.css'
import NavBar from './components/NavBar.vue'
import { mapActions } from 'vuex'

// Export app
export default {
    name: "App",

    components: { NavBar },
    methods: {
        /**
         * Map actions from store
         */
        ...mapActions([
            'loadUser',
        ]),
    },
    async created() {
        // Do a call to loadUser after creating the main App, to make sure users
        // that were logged-in in a previous, still valid, session, or are 
        // logged-in in a concurrent session, are again logged in. This is the
        // first lifecycle hook where we can do this, since earlier hooks don't
        // have access to the store yet.
        await this.loadUser();
    }
}
</script>

<style>

</style>
