<template>
    <!-- Section Heading -->
    <h3>Recent Solves</h3>
    <hr>

    <!-- Recent challenges -->
    <table class="table table-hover">
        <tbody v-if="solvesLength == 0">
            <tr>
                Could not load recent solves, please try refreshing the page.
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-for="solve in mostRecentSolves()" :key="solve.index" v-on:click="this.selectChallenge(solve.identifier)">
                <td>{{ solve.username }} solved {{ solve.title }} ({{ solve.points }}p) - {{ solve.date }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
// Import methods
import { mapActions } from 'vuex'

// Export app
export default {
  name: 'ChallengeSolves',

  methods: {
      /**
       * Get a list of the most recent solves of length <amount>.
       * @param amount: number of most recent solves to compute.
       */
      mostRecentSolves(amount){
          // Get all solves
          var solves = this.$store.state.recentSolves.slice();

          // Sort by timestamp
          solves.sort((a, b) =>{
              return b.timestamp - a.timestamp;
          })

          // Get <amount> most recent solves
          solves = solves.slice(0, amount || solves.length);

          // Compute date attribute
          for (var solve of solves){
              // Transform timestamp to date
              var date = new Date(solve.timestamp * 1000);
              // Transform to date format
              solve.date = date.getFullYear() +
                           '/'   + String(date.getMonth  ()+1).padStart(2, '0') +
                           '/'   + String(date.getDate   ()  ).padStart(2, '0') +
                           ' - ' + String(date.getHours  ()  ).padStart(2, '0') +
                           ':'   + String(date.getMinutes()  ).padStart(2, '0') +
                           ':'   + String(date.getSeconds()  ).padStart(2, '0');
          }

          // Return solves
          return solves;
      },

      /**
       * Map actions from store
       */
      ...mapActions([
          'loadRecentSolves',
          'selectChallenge',
      ])
  },

  computed: {
      /**
       * Get the number of loaded solves.
       */
      solvesLength() {
          return this.$store.state.recentSolves.length;
      }
  },

  beforeMount() {
      // Load the solves before mounting
      this.loadRecentSolves(4);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    td {
        text-align: right;
    }

    table {
        font-weight: 350;
    }

    tr:hover {
        cursor: pointer;
    }

    @media only screen
    and (min-width : 992px) {
        h3 {
            margin-top: 0px;
            text-align: right;
        }
    }

    @media only screen
    and (max-width : 992px) {
        h3 {
            margin-top: 20px;
            text-align: center;
        }
    }
</style>
