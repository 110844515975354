<template>
    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <h2>Practice sessions</h2>
                    <table class="table table-hover table-sm">
                        <thead>
                            <tr>
                                <td>Date</td>
                                <td>Challenges</td>
                                <td>Link</td>
                                <td>Passcode</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>24 January 2022</td>
                                <td><a href="https://hackthebox.eu" target="_blank">hackthebox.eu <i class="fa fa-external-link" aria-hidden="true"></i></a> - PWN - racecar & You know 0xDiablos</td>
                                <td><a href="https://utwente-nl.zoom.us/rec/share/zfadtTc4a51XxDCBjiR0UZD-wZMOzbipzW2Wj_AMzaiNHtdx9flGaYvcMzXSrcr7.7iCQgPyut2Uge_Bm" target="_blank">Recording <i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                <td>0!0?Cy%&</td>
                            </tr>
                            <tr>
                                <td>17 January 2022</td>
                                <td><a href="https://ths.eemcs.utwente.nl/challenges" target="_blank">THS <i class="fa fa-external-link" aria-hidden="true"></i></a> - PWN - Sustainable Forestry</td>
                                <td><a href="https://utwente-nl.zoom.us/rec/share/lHWkqfNJufkaPMXrusjSfaOb9DkfkwAozBT2RLHEW0gjhMTKY7uujBFp7Ws_DRAT.YX1LNOEXw5gpup_A" target="_blank">Recording <i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                <td>wWA.6vXb</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Import components
// import [component name] from './[component name].vue'

// Export app
export default {
  name: 'ResourcePracticeSessions',

  // Set components
  components: {
    //   [component name],
  },

  methods: {
      // No methods yet
  },

  mounted(){
  },
}
</script>

<style>

</style>
