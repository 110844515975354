<template>
    <table class="table table-hover">
        <!-- Show challenge -->
        <tbody>
            <tr v-for="patch of listPatches()" :key="patch.version">
                <PatchlogPatch :patch="patch" />
            </tr>
        </tbody>
    </table>
</template>

<script>
// Import methods
import { mapActions } from 'vuex'

// Import components
import PatchlogPatch from './PatchlogPatch.vue';

// Export app
export default {
    name: 'PatchlogList',

    // Set components
    components: {
        PatchlogPatch,
    },

    methods: {
        listPatches() {
            // Return all patches of patchlog
            return Object.values(this.$store.state.patches);
        },

        /**
       * Map actions from central store
       */
        ...mapActions([
            'loadPatches',
        ]),
    },
    
    beforeMount() {
        this.loadPatches();
    },
}
</script>

<style scoped>

</style>
