<template>
    <!-- Section Heading -->
    <h3>Recent Challenges</h3>
    <hr>

    <!-- Recent challenges -->
    <table class="table table-hover">
        <tbody v-if="challengesLength == 0">
            <tr>
                Could not load recent challenges, please try refreshing the page.
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-for="challenge in mostRecentChallenges(4)" :key="challenge.title" v-on:click="this.selectChallenge(challenge.id)">
                <td>{{ challenge.category.toUpperCase() }} - {{ challenge.title }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
// Import methods
import { mapActions } from 'vuex'

export default {
  name: 'ChallengeRecent',

  methods: {
      /**
       * Get a list of the most recent challenges of length <amount>.
       * @param amount: number of most recent challenges to compute.
       */
      mostRecentChallenges(amount){
          // Get all challenges
          var challenges = Object.values(this.$store.state.challenges);

          // Sort by time updated
          challenges.sort((a, b) =>{
              return new Date(b.updated) - new Date(a.updated);
          })

          // Return <amount> most recent
          return challenges.slice(0, amount || challenges.length)
      },

      /**
       * Map actions from central store
       */
      ...mapActions([
          'selectChallenge',
      ]),
  },

  computed: {
      /**
       * Get the number of loaded challenges.
       */
      challengesLength() {
          return Object.keys(this.$store.state.challenges).length;
      }
  },
}
</script>

<style scoped>
  table {
      font-weight: 350;
  }
  tr:hover {
      cursor: pointer;
  }

  @media only screen
  and (min-width : 992px) {
      h3 {
          margin-top: 0px;
      }
  }

  @media only screen
  and (max-width : 992px) {
      h3 {
          margin-top: 20px;
          text-align: center;
      }
  }
</style>
