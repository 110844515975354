<template>
  <div>
    <h2 class="score-header">Challenge Completion</h2>
    <hr />
    <Radar class="graph" :data="chartData" :options="chartOptions" />
  </div>
</template>
    
<script>
import { Radar } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  Filler,
  RadialLinearScale,
  PointElement,
  LineElement,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  Filler,
  PointElement,
  LineElement,
  RadialLinearScale
);

export default {
  name: 'UserCompletionGraph',

  props: {
    solves: {
      default: []
    }
  },

  components: {
    Radar
  },

  methods: {
    
    /**
     * Computes the completion distribution according to a set of categories and their 
     * challenge count, and a list of solves.
     * @param {*} categories The set of categories and their challenge counts.
     * @param {*} solves The solves submitted by the user.
     */
    computeDistribution(categories, solves) {
      // Prepare distribution dictionary.
      const distribution = {};
      Object.entries(categories).forEach((category) => {
        distribution[category[0]] = 0;
      });

      // Count all solves.
      for (let i = 0; i < solves.length; i++) {
        distribution[solves[i].challenge.category] += 1;
      }

      // Normalize to fractions.
      Object.entries(categories).forEach(([category, value]) => {
        distribution[category] = (distribution[category] / value);
      });

      return distribution;
    }
  },

  computed: {
    /**
     * Obtains the categories and pairs them with their challenge count.
     */
    categories() {
      const result = {};

      Object.entries(this.$store.state.challenges).forEach(function (x) {
        const challenge = x[1];

        if (challenge.category in result) {
          result[challenge.category] += 1;
        } else {
          result[challenge.category] = 1;
        }
      });

      return result;
    },

    /**
     * Obtains the chart information to display in the radar chart.
     */
    chartData() {
      // Compute the completion distribution.
      const distribution = this.computeDistribution(this.categories, this.solves);

      // Convert to chart.js data.
      const labels = [];
      const data = [];
      Object.entries(distribution).forEach(([key, value]) => {
        labels.push(key);
        data.push(value);
      });

      // Construct chart dataset.
      return {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1,
        }]
      };
    },

    /**
     * Obtains the chart display parameters.
     */
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          r: {
            min: 0,
            max: 1,
            ticks: {
              display: false,
              format: {
                style: 'percent'
              },
              stepSize: 0.20
            },
            pointLabels: {
              font: {
                size: 15
              }
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        }
      };
    }
  }
}
</script>
    
<style scoped>
.graph {
  max-height: 400px;
}
</style>
    