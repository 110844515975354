<template>
    <div class="main-container">
        <!-- Header -->
        <div class="row">
            <div class="col-lg-7 col-md-5">
                <h1>Twente Hacking Squad</h1>
                
                <p style="font-size: 1.75em;">Capturing Flags since 2015</p>

            </div>
            <div class="col-lg-5 col-md-7 icon-column">
                <img style="height: 15em;" src="../../assets/g8793.png" alt="THS logo">
            </div>
        </div>

        <!-- Main Buttons -->
        <div class="row">
            <div class="col-lg-3">
                <router-link to="challenges" class="btn btn-lg home-page-button home-page-button-lg">
                    <img style="height: 5em; margin: 1em 0;" src="../../assets/programming.svg">  <br />
                    Try a Challenge
                </router-link>
            </div>
            <div class="col-lg-3">
                <a href="https://www.lists.utwente.nl/cgi-bin/wa.exe?SUBED1=THS&A=1" target="_blank" class="btn btn-lg home-page-button home-page-button-lg">
                    <img style="height: 5.5em; margin: 0.75em 0;" src="../../assets/email.svg">  <br />
                    Join the Mailing&nbsp;List
                </a>
            </div>
            <div class="col-lg-3">
                <a href="https://mattermost.dacs.utwente.nl/signup_user_complete/?id=8f5qrf9jpj81fymcfy6i8c5ore&md=link&sbr=su" target="_blank" class="btn btn-lg home-page-button home-page-button-lg">
                    <img style="height: 5.5em; margin: 0.75em 0;" src="../../assets/mattermost.svg"> <br />
                    Join the Mattermost*
                </a>
            </div>
            <div class="col-lg-3">
                <button @click="scrollToSchedule()" class="btn btn-lg home-page-button home-page-button-lg">
                    <img style="height: 6em; margin: 0.5em 0;" src="../../assets/schedule.png"> <br />
                    Workshops Schedule
                </button>
            </div>
            <div class="col-md-12">
                * University of Twente email required.
            </div>
        </div>

        <hr />

        <!-- Who are we -->
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <h2>Who are we?</h2>
                <p>
                    We are a group of Ph.D., Master and Bachelor students from the <a href="https://utwente.nl/" target="_blank">University of Twente</a> that are interested in hacking and computer security.
                    We play <a href="https://en.wikipedia.org/wiki/Capture_the_flag_(cybersecurity)" target="_blank">Capture-The-Flag (CTF)</a> and rank ourselves on the leaderboards of <a href="https://ctftime.org/team/200263" target="_blank">CTFTime.org</a>.
                </p>
            </div>
            <div class="col-lg-6 col-md-12 icon-column">
                <img src="../../assets/flag.png">
            </div>
        </div>

        <hr />

        <!-- What do we do -->
        <div class="row">
            <div class="col-lg-6 col-md-12 icon-column">
                <img src="../../assets/programming.svg">
            </div>
            <div class="col-lg-6 col-md-12">
                <h2>What do we do?</h2>
                <p>
                    We keep ourselves busy with anything related to the following subjects:
                </p>
                <ul>
                    <li>Programming</li>
                    <li>Cryptography</li>
                    <li>Digital Forensics</li>
                    <li>Reverse Engineering</li>
                    <li>Binary Exploitation / Pwning</li>
                    <li>Web hacking</li>
                </ul>

                <div class="col-lg-5 col-md-12">
                    <router-link to="challenges" class="btn btn-lg home-page-button-small">
                        Try a Challenge
                    </router-link>
                </div>
            </div>
        </div>

        <hr />

        <!-- Workshop Schedule -->
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <h2 id="header-schedule">When do we Meet?</h2>
                <p>
                    We organize on-campus hacking sessions that are also streamed online via Microsoft Teams.
                    Both beginners and experts are welcome.
                    Participation is free and no special sign-up is required.                      
                </p>
                <table class="table table-hover">
                    <thead>
                        <th class="table-cell">Date</th>
                        <th class="table-cell">Time</th>
                        <th class="table-cell">Location</th>
                        <th class="table-cell">Subject</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>November 21, 2024</td>
                            <td>18:30 to 20:00</td>
                            <td>Hal B - 2F &amp; online</td>
                            <td>Session - <i>Pwn</i></td>
                        </tr>
                        <tr>
                            <td>November 28, 2024</td>
                            <td>18:30 to 20:00</td>
                            <td>Hal B - 2F &amp; online</td>
                            <td>Session - <i>CTF Preparation</i></td>
                        </tr>
                        <tr>
                            <td>December 5, 2024</td>
                            <td>18:30 to 20:00</td>
                            <td>Hal B - 2F &amp; online</td>
                            <td>Session - <i>CTF Preparation</i></td>
                        </tr>
                        <tr>
                            <td>December 12, 2024</td>
                            <td>18:30 to 20:00</td>
                            <td>Hal B - 2F &amp; online</td>
                            <td>Session - <i>CTF Preparation</i></td>
                        </tr>
                        <tr>
                            <td>December 14-15, 2024</td>
                            <td>All Weekend!</td>
                            <td>ZI 2042 &amp; online</td>
                            <td>Participate in CTF</td>
                        </tr>
                    </tbody>
                </table>

                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <a href="https://calendar.google.com/calendar/u/3?cid=MWZ1aGxnZW1nb2VyOTJsa2M4c3Ria21hYm9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ" target="_blank" class="btn btn-lg home-page-button-small">
                            Add to Google Calendar
                        </a>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <button @click="copyIcsLink($event, 'https://calendar.google.com/calendar/ical/1fuhlgemgoer92lkc8stbkmabo%40group.calendar.google.com/public/basic.ics')" class="btn btn-lg home-page-button-small">
                            Copy ICS Link
                        </button>
                    </div>
                </div>

            </div>
            <div class="col-lg-6 col-md-12 icon-column">
                <img src="../../assets/schedule.png">
            </div>
            
        </div>

        <hr />

        <!-- Joining THS -->
        <div class="row">
            <div class="col-12" style="text-align: center;">
                <h2>Want to Join?</h2>
                <div class="row justify-content-center">
                    <div class="col-lg-3">
                        <a href="https://www.lists.utwente.nl/cgi-bin/wa.exe?SUBED1=THS&A=1" target="_blank" class="btn btn-lg home-page-button home-page-button-lg">
                            <img style="height: 5.5em; margin: 0.75em 0;" src="../../assets/email.svg">  <br />
                            Join the Mailing List
                        </a>
                    </div>
                    <div class="col-lg-3">
                        <a href="https://mattermost.dacs.utwente.nl/signup_user_complete/?id=8f5qrf9jpj81fymcfy6i8c5ore&md=link&sbr=su" target="_blank" class="btn btn-lg home-page-button home-page-button-lg">
                            <img style="height: 5.5em; margin: 0.75em 0;" src="../../assets/mattermost.svg"> <br />
                            Join the Mattermost*
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="text-align: center;">
            * University of Twente account required.
        </div>

        <hr />

        <!-- People -->
        <!-- <div class="row">
            <div class="col-md-12">
                <h2>Current Staff Members</h2>
                <ul>
                    <li><a href="https://conand.me" target="_blank">Andrea Continella</a></li>
                    <li><a href="https://thijsvane.de" target="_blank">Thijs van Ede</a></li>
                    <li><a href="https://people.utwente.nl/p.jakubeit" target="_blank">Philipp Jakubeit</a></li>
                    <li><a href="https://people.utwente.nl/y.a.m.kortekaas" target="_blank">Yoep Kortekaas</a></li>
                    <li><a href="https://people.utwente.nl/j.a.l.starink" target="_blank">Jerre Starink</a></li>
                    <li><a href="https://people.utwente.nl/f.mazzone" target="_blank">Federico Mazzone</a></li>
                    <li><a href="https://people.utwente.nl/c.gupta" target="_blank">Chakshu Gupta</a></li>
                    <li><a href="https://people.utwente.nl/z.kucsvan" target="_blank">Zsolt Kucsván</a></li>
                    <li><a href="https://jorik.xyz" target="_blank">Jorik van Nielen</a></li>
                    <li><a href="https://marc.damie.eu" target="_blank">Marc Damie</a></li>
                    <li><a href="https://people.utwente.nl/m.grella" target="_blank">Matteo Grella</a></li>
                </ul>
            </div>
        </div> -->
    </div>
</template>

<script>

// Export app
export default {
  name: 'HomepageMain',

  methods: {
    async copyIcsLink(e, link) {
        const button = e.target;
        const originalText = button.innerText;

        // Copy text to clipboard
        await navigator.clipboard.writeText(link);

        button.innerText = "Copied!";

        // Wait for 3 seconds
        await new Promise(r => setTimeout(r, 3000));

        // Reset state of copy button
        button.innerText = originalText;    
    },

    scrollToSchedule() {
        document.getElementById("header-schedule").scrollIntoView();
    }
  },


  // Change height to auto
  mounted() {
      document.body.style.height = "auto";
  },
}
</script>


<style lang="css" scoped>
    .row {
        margin: 1em 0 3em 0;
    }
    
    .icon-column {
        padding: 2em;
        text-align: center;
    }

    .icon-column > img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        height: 12em;
    }
    
    .home-page-button-small {
        background-color: #800080;
        margin-bottom: 10px;
        color: white;
        text-align: center;
        width: 100%;
        font-size: 15px;
        font-weight: bold;
    }

    .home-page-button {
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
        font-size: 1.1em;
        transition-property: background, filter, transform;
        
    }

    .home-page-button:active {
        transform: translateY(0.1em);
    }

    .home-page-button:hover {
        background: rgb(226, 226, 226);
        transform: scale(1.05);
    }

    .home-page-button-lg {
        /* height: 10em; */
        padding: 1em;
        font-size: 1.4em;
        font-weight: bold;
    }
</style>