// Define sort options
export const sortOptions = Object.freeze({
    AlphabeticalAscending : 'Alphabetical A-Z',
    AlphabeticalDescending: 'Alphabetical Z-A',
    DifficultyAscending   : 'Difficulty Easy-Hard',
    DifficultyDescending  : 'Difficulty Hard-Easy',
});

// Define response options
export const responseOptions = Object.freeze({
    Pending: 0,
    Success: 1,
    Failed : 2,
});

// Define field states 
// TODO better name
export const FieldState = Object.freeze({
    Empty  : 0,
    Invalid: 1,
    Valid  : 2,
});