<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <router-link class="navbar-brand" :to="{ name: 'homepage' }">
                <img src="../assets/g8793.png" class="thslogo" alt="THS logo"> <b>THS</b>
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarScroll">
                <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                    <li class="nav-item">
                        <router-link class="nav-link" aria-current="page" :to="{ name: 'challenges' }">Challenges</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" aria-current="page" :to="{ name: 'highscores' }">Highscores</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" aria-current="page" :to="{ name: 'resources' }">Resources</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" aria-current="page" :to="{ name: 'writeups' }">Writeups</router-link>
                    </li>
                </ul>
                <div class="d-flex">
                    <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                        <li class="nav-item">
                            <router-link class="nav-link" aria-current="page" :to="{ name: 'patchlog' }">Patch Log</router-link>
                        </li>

                        <li class="nav-item dropdown"  v-show="this.authenticated === true">
                            <a class="nav-link active dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ username }}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <router-link class="nav-link active" aria-current="page" :to="{ path: '/user/' + this.$store.state.user.id }">Profile</router-link>
                                <router-link class="nav-link active" aria-current="page" :to="{ name: 'logout' }">Logout</router-link>
                            </ul>
                        </li>
                        <li class="nav-item"  v-show="this.authenticated === false">
                            <router-link class="nav-link active" aria-current="page" :to="{ name: 'signup' }">Sign up</router-link>
                        </li>
                        <li class="nav-item"  v-show="this.authenticated === false">
                            <router-link class="nav-link active" aria-current="page" :to="{ name: 'login' }">Login</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex'
// Import components
// import [component name] from './[component name].vue';

// Export app
export default {
    name: 'NavBar',

    // Set components
    components: {
        // [component name],
    },

    methods: {
        /**
         * Map getters from store
         */
        ...mapGetters([
            'authenticated',
        ]),
    },

    computed: {
        /**
         * Map getters from store
         */
        ...mapGetters([
            'authenticated',
        ]),

        /**
         * Compute the username, if available
         */
        username() {
            // If we are authenticated, return the username
            if (this.authenticated) {
                return this.$store.state.user.name;
            } 

            // Otherwise, return null
            return null;
        }
    },

    mounted() {
        // no content loaded on-mount
    },
}
</script>

<style scoped>

.navbar-nav-scroll {
    --bs-scroll-height: 100px;
}

.thslogo {
    width: 30px;
}

</style>
