<template>
  <div class="col-lg-12">
    <h2 class="score-header">Score Over Time</h2>
    <hr />
    <Line 
      :data="chartData"
      :options="chartOptions"
      class="graph" />
  </div>
</template>

<script>
import 'chartjs-adapter-moment';
import { Line } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale
)

export default {
  name: 'UserScoreGraph',

  props: {
    solves: {
      default: []
    }
  },

  components: {
    // Apparently 'Line' is a reserved component name, so we need to disable 
    // eslint for that line.
    // eslint-disable-next-line vue/no-reserved-component-names
    Line
  },

  methods: {

  },

  computed: {
    /**
     * Obtains the chart information to display in the line chart.
     */
    chartData() {       
      const labels = [];
      const data = [];
      
      // Compute the score progression.
      let totalScore = 0;
      for (let i = this.solves.length - 1; i >= 0; i--) {
        labels.push(new Date(this.solves[i].timestamp));
        totalScore += this.solves[i].points;
        data.push(totalScore);
      }

      // Construct data set.
      return {
        labels: labels,
        datasets: [ {
          label: "Score",
          data: data,
          borderColor: 'green',
        } ]
      };
    },

    /**
     * Obtains the chart information to display in the line chart.
     */
    chartOptions() { 
      return { 
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              tooltipFormat: 'MMM DD HH:mm'
            },
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      };
    }
  }
}
</script>

<style scoped>
.graph {
  width: 100%;
  max-height: 400px;
}
</style>
