<template>
    <div class="container-fluid">
        <!-- Header -->
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <h1>Resources</h1>
            </div>
        </div>

        <!-- Workshops -->
        <ResourceWorkshops />

        <!-- Practice Sessions -->
        <ResourcePracticeSessions />

        <!-- CTF Practice Websites -->
        <ResourcePracticeWebsites />

        <!-- Tools -->
        <ResourceTools />
    </div>
</template>

<script>
// Import components
import ResourceWorkshops from './ResourceWorkshops.vue';
import ResourcePracticeSessions from './ResourcePracticeSessions.vue';
import ResourcePracticeWebsites from './ResourcePracticeWebsites.vue';
import ResourceTools from './ResourceTools.vue';

// Export app
export default {
  name: 'ResourceMain',

  // Set components
  components: {
    ResourceWorkshops,
    ResourcePracticeSessions,
    ResourcePracticeWebsites,
    ResourceTools,
},

  methods: {
      // No methods yet
  },

  // Change height to auto
  mounted(){
      document.body.style.height = "auto";
  },
}
</script>


