<template>
  <div class="container-fluid">
    <!-- Header -->
    <div class="row justify-content-center">
      <UserHead :userId="userId" />
    </div>
    
    <div class="row">
      <div class="col-lg-6">
        <UserCategoryGraph :solves="solves" />
      </div>
      <div class="col-lg-6">
        <UserCompletionGraph :solves="solves" />
      </div>
    </div>

    <!-- Individual solves -->
    <div class="row justify-content-center">
      <UserSolves :solves="solves" />
    </div>

    <!-- Score over time -->
    <div class="row">
      <UserScoreGraph :solves="solves" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import UserHead from './UserHead.vue';
import UserScoreGraph from './UserScoreGraph.vue';
import UserCompletionGraph from './UserCompletionGraph.vue';
import UserCategoryGraph from './UserCategoryGraph.vue';
import UserSolves from './UserSolves.vue';

export default {
  name: 'UserMain',

  props: [
    'userId'
  ],

  components: {
    UserHead,
    UserScoreGraph,
    UserCompletionGraph,
    UserCategoryGraph,
    UserSolves
  },

  methods: {
    ...mapActions([
      'loadChallenges',
      'loadUserProfile',
    ]),
  },

  computed: {
    /**
     * Obtains the solves that are submitted by the user.
     */
    solves() {
      const result = this.userId in this.$store.state.userProfiles
        ? this.$store.state.userProfiles[this.userId].solves
        : [];

      result.sort(function(a, b) {
        const x = new Date(a.timestamp);
        const y = new Date(b.timestamp);
        return y - x;
      });

      return result;
    }
  },

  beforeMount() {
    // Note: We need the challenges to be loaded for the completion graph to compute the percentages.
    this.loadChallenges();

    this.loadUserProfile(this.userId).catch(error => {
      // Redirect to 404 page if the user profile was not found.
      if ('response' in error && error.response.status == 404) {
        this.$router.push({ name: 'NotFound' });        
      }
    });
  },

  mounted() {
    document.body.style.height = "auto";
    window.scrollTo(0, 0);
  }
}
</script>

<style>
h2.score-header {
  margin-bottom: 0px;
}
</style>
