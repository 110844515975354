<template>
    <td>
        <h2>Version {{ version }}</h2>
        <span class="soft">Date: {{ date }}</span>
        <ul>
            <li v-for="change in changes" :key="change"> <!-- key should preferably be the array index, but not sure how to accomplish that-->
                {{ change }}
            </li>
        </ul>
    </td>
</template>

<script>
// Import components
// import [component name] from './[component name].vue';

// Export app
export default {
    name: 'PatchlogPatch',

    props: [
        'patch'
    ],

    // Set components
    components: {
        // [component name],
    },

    methods: {
        // No methods yet
    },

    computed: {
        
        /** 
         * Return the version associated to this patch
         */
        version() {
            return this.patch.version;
        },

        /** 
         * Return the date this patch was publishes
         */
        date() {
            return this.patch.date;
        },

        /** 
         * Return the actual changes that were applied in this patch
         */
        changes() {
            return this.patch.patches;
        },
    }

}
</script>

<style scoped>

    h2 {
        margin-bottom:5px;
        margin-top:20px;
    }

    ul {
        margin-left:15px;
    }
</style>
