<template>
  <div>
    <h2 class="score-header">Score Distribution</h2>
    <hr />
    <Doughnut
      v-if="solves.length > 0"
      class="graph"
      :data="chartData"
      :options="chartOptions"
      />
    <div class="message" v-if="solves.length == 0">
      <h3>
        No data
      </h3>
    </div>
  </div>
</template>
  
<script>
import { Doughnut } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
);

const categoryColors = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
];

export default {
  name: 'UserCategoryGraph',

  props: {
    solves: {
      default: []
    },
    mode: {
      default: "total-score"
    }
  },

  components: {
    Doughnut
  },

  methods: {
    /**
     * Computes the score distribution based on a set of solves.
     * @param {*} solves The set of solves submitted by the user.
     * @param {*} selector A function that maps a solve to a weight.
     */
    computeDistribution(solves, selector) {
      const distribution = {};      
      for (let i = 0; i < solves.length; i++) {
        const category = solves[i].challenge.category;
        if (category in distribution) {
          distribution[category] += selector(solves[i]);
        } else {
          distribution[category] = selector(solves[i]);
        }
      }
      return distribution;
    }
  },

  computed: {
    /**
     * Obtains the chart information to display in the doughnut chart.
     */
    chartData() {
      // Determine how we are weighting every solution.
      let selector = function() { return 0; };
      switch (this.mode) {
        case 'challenge-count':
          // Simple challenge count means every solution is weighted the same.
          selector = function() { return 1; };
          break;
        case 'total-score':
          // Map every solve to its rewarded points.
          selector = function(solve) { return solve.points; };
          break;
      }

      // Compute the distribution.
      const distribution = this.computeDistribution(this.solves, selector);

      // Convert to chart.js data.
      const labels = [];
      const data = [];
      Object.entries(distribution).forEach(([key, value]) => {
        labels.push(key);
        data.push(value);
      });

      // Construct chart dataset.
      return {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: categoryColors.map(function(c) { return c + "80"}), 
          borderColor: categoryColors,
          borderWidth: 1,
          hoverOffset: 6,
        }]
      };
    },

    /**
     * Obtains the chart display parameters.
     */
    chartOptions() { 
      return { 
        responsive: true,
        maintainAspectRatio: true,
        layout: {
            padding: {
              // Added to prevent the chart from clipping due to the hover offset.
              bottom: 3
            }
        },
        plugins: {
          legend: {
            labels: {
              font: {
                size: 15
              }
            }
          }
        }
      };
    }
  }
}
</script>

<style scoped>
.message {
  text-align: center;
  height: 400px;
}

.message > h3 {
  line-height: 350px;
  color: #808080;
}

.graph {
  max-height: 400px;
}
</style>
