<template lang="html">
    <!-- Show categories -->
    <div v-for="category in categories" :key="category">
        <!-- Add new challenge category -->
        <ChallengeCategory :category="category"></ChallengeCategory>
    </div>
</template>

<script>
// Import components
import ChallengeCategory from './ChallengeCategory.vue'

export default {
    name: 'ChallengeOverview',

    // Set components
    components: {
        ChallengeCategory,
    },

    computed: {
        /**
         * Get a list of all available categories
         */
        categories() {
            return Object.keys(this.$store.state.categories).sort()
        }
    }
}
</script>

<style lang="css" scoped>

</style>
