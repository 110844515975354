<template>
    <div class="row justify-content-center">
        <div class="col-md-12">
            <h2>Tools</h2>
            Here we list some of the main tools we use for playing CTF challenges.
            Some CTF challenges provide files that can act as malware, therefore, it is always recommended to play a CTF in a VM.
            Additionally, you can customize your VM to have usefull tools installed for file analysis and solving challenges.
            Most of these tools are available by default in <a href="https://www.kali.org/" target="_blank">Kali Linux <i class="fa fa-external-link" aria-hidden="true"></i></a>.

            <div class="alert alert-warning alert-dismissible fade show" role="alert">
                If you know any good tools that you would like to see listed here, please contact Federico Mazzone, Thijs van Ede, Yoep Kortekaas or Jerre Starink.
            </div>


            <h3>Crypto</h3>
            <ul>
                <li><a href="https://gchq.github.io/CyberChef/">CyberChef</a>, an online tool for encoding/decoding and performing various cryptographic operations.</li>
                <li><a href="https://www.dcode.fr/">dcode</a>, a website that offers various decoding tools.</li>
                <li><a href="https://pycryptodome.readthedocs.io/en/latest/index.html">PyCryptodome</a>, a python library for many cryptographic functions.</li>
                <li><a href="https://www.sagemath.org/">Sage</a>, a python-based language implementing many mathematical functions useful for cryptography.</li>
                <li><a href="https://github.com/Ganapati/RsaCtfTool">RsaCtfTool</a>, an open source RSA multi attacks tool that unciphers data from weak public key and can recover private key for the given public key</li>
            </ul>

            <h3>Forensics</h3>
            As there are many different branches of forensics, we subdivided the tools in various categories:

            <h4>File & memory forensics</h4>
            <ul>
                <li><a href="https://github.com/ReFirmLabs/binwalk">binwalk</a>, a tool for extracting (hidden) files from other files.</li>
                <li><a href="https://www.volatilityfoundation.org/">volatility</a>, a tool for inspecting memory.</li>
            </ul>

            <h4>Steganography</h4>
            <ul>
                <li><a href="http://steghide.sourceforge.net/">steghide</a>, a common tool for steganography.</li>
                <li><a href="https://en.kali.tools/all/?tool=1762">stegsolve</a>, a visual tool for inspecting images containing steganography messages.</li>
                <li><a href="https://www.spammimic.com">spammimic</a>, a tool to encode and decode text messsages to look innocent.</li>
            </ul>

            <h4>Web forensics</h4>
            <ul>
                <li><a href="https://scapy.net/">Scapy</a>, a python library for inspecting and manipulating network traffic.</li>
                <li><a href="https://www.wireshark.org/">wireshark</a>, a tool for inspecting network traffic.</li>
                <li><a href="https://www.xplico.org/">xplico</a>, a tool for extracting files from pcaps.</li>
            </ul>

            <h3>Misc</h3>
            There are of course many tools that fall within the miscellaneous category, here we list a couple:
            <ul>
                <li><a href="https://github.com/zardus/ctf-tools">ctf-tools</a>, a GitHub repository listing various CTF tools.</li>
                <li><a href="https://github.com/JohnHammond/ctf-katana">ctf-katana</a>, a GitHub repository listing various tools and commands that are helpful for CTF challenges.</li>
                <li><a href="https://ide.kaitai.io/">katai</a>, a tool for parsing binary files.</li>
                <li><a href="https://github.com/tesseract-ocr/tesseract">tesseract</a>, a linux-based tool for Optical Character Recognition (OCR).</li>
                <li><a href="https://mkvtoolnix.download/">mkvtoolnix</a>, a tool for creating altering and inspecting Matroska files.</li>
            </ul>

            <h3>Pwn</h3>
            <ul>
                <li><a href="https://github.com/swisskyrepo/PayloadsAllTheThings">PayloadsAllTheThings</a>, a GitHub repository listing many different payloads for all kinds of web CTF challenges.</li>
                <li><a href="https://www.revshells.com/">revshells</a>, a website listing commands to obtain a reverse shell for many languages.</li>
                <li><a href="https://github.com/Gallopsled/pwntools">pwntools</a>, a python library for automating pwning actions.</li>
            </ul>

            <h3>Reversing</h3>
            <ul>
                <li><a href="https://hex-rays.com/IDA-pro/">IDA Pro</a>, main (PAID) reverse engineering tool.</li>
                <li><a href="https://ghidra-sre.org/">Ghidra</a>, main open source reverse engineering tool.</li>
                <li><a href="https://software.intel.com/content/www/us/en/develop/articles/pin-a-dynamic-binary-instrumentation-tool.html">pin</a>, a binary instrumentation tool.</li>
                <li><a href="https://github.com/pwndbg/pwndbg">pwndbg</a>, a plug-in that makes GDB a bit more manageble.</li>
                <li><a href="https://github.com/radareorg/radare2">radare2</a>, open source reverse engineering tool.</li>
                <li><a href="https://strace.io/">strace</a>, a tool for inspecting system calls.</li>
                <li><a href="https://cuckoosandbox.org/">Cuckoo</a>, a malware analysis sandbox.</li>
                <li><a href="https://www.hybrid-analysis.com/">Hybrid Analysis</a>, a malware analysis sandbox.</li>
            </ul>

            <h3>Web</h3>
            <ul>
                <li><a href="https://portswigger.net/burp/communitydownload"></a>, a tool for pentesting and automating analysis of websites.</li>
                <li><a href="https://learning.postman.com/docs/getting-started/installation-and-updates/#installing-postman-on-linux">Postman</a>, a tool for automating API requests.</li>
                <li><a href="https://mindedsecurity.github.io/jstillery/">JStillery</a>, a JavaScript deobfuscation tool.</li>
                <li><a href="https://github.com/swisskyrepo/PayloadsAllTheThings">PayloadsAllTheThings</a>, a GitHub repository listing many different payloads for all kinds of web CTF challenges.</li>
                <li><a href="https://www.revshells.com/">revshells</a>, a website listing commands to obtain a reverse shell for many languages.</li>
            </ul>
        </div>
    </div>
</template>

<script>
// Import components
// import [component name] from './[component name].vue'

// Export app
export default {
  name: 'ResourceTools',

  // Set components
  components: {
    //   [component name],
  },

  methods: {
      // No methods yet
  },

  mounted(){
  },
}
</script>

<style>

</style>