<template>
    <div class="row justify-content-center">
        <div class="col-md-12">
            <h2>Workshops</h2>
            Here we list the recordings and slides of the workshops for future reference.
            <table class="table table-hover table-sm">
                <thead>
                    <tr>
                        <th>Workshop</th>
                        <th>Slides</th>
                        <th>Recordings</th>
                        <th>Challenges (Sorted by (approximated) difficulty)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Introduction & Lockpicking</td>
                        <td><a href="/media/resources/slides/workshop_intro.pdf" target="_blank">Slides <i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td><a href="https://utwente-nl.zoom.us/rec/share/9maE20_A5Hmagwyj4JLWSEqr8jM0geAod2q3MFurHco6rRrGE-TY6n-GA7xwyfvj.MEYNWfogm8E7UrDF" target="_blank">Recording <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: 1?yn9si7)</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Web hacking</td>
                        <td><a href="/media/resources/slides/workshop_web_1.pdf" target="_blank">Slides <i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td><a href="https://utwente-nl.zoom.us/rec/share/nopUNqzcsbqoISQxIDomzczlZK0n0gd7H_JF0pA74gNZ-kfUYeLlwT98bBOBF4zc.T7U40-OwSi_CYcqO" target="_blank">Recording (Part 1) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: ^aX3jZf!)<br>
                            <a href="https://utwente-nl.zoom.us/rec/share/tB0NjXGHixFtsZAeyw7ZZ9XhZCGsYv0vahMbUX4uj8CoVNJt2q9yH0bfQvAQbHCJ.XRBBAI6UKFp7eaI3" target="_blank">Recording (Part 2) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: Qh&2nWb$)</td>
                        <td>
                            <ul>
                                <li><a href="https://app.hackthebox.com/challenges/67" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a> - Web - Emdee five for life (Web automation) </li>
                                <li><a href="https://play.picoctf.org/practice/challenge/142?category=1&page=1" target="_blank">picoctf.org - Web - Who are you? (HTTP protocol) <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                                <li><a href="https://play.picoctf.org/practice/challenge/88?category=1&page=2" target="_blank">picoctf.org - Web - Web Gauntlet (SQL injection) <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                                <li><a href="https://play.picoctf.org/practice/challenge/174?category=1&page=2" target="_blank">picoctf.org - Web - Web Gauntlet 2 (SQL injection) <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                                <li><a href="https://play.picoctf.org/practice/challenge/128?category=1&page=3" target="_blank">picoctf.org - Web - Web Gauntlet 3 (SQL injection) <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                                <li><a href="https://alf.nu/alert1" target="_blank">alf.nu - Alert(1) to win (XSS) <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                                <li><a href="https://overthewire.org/wargames/natas/" target="_blank">overthewire.org - Natas (Server side security) <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                                <li><a href="https://tryhackme.com/room/owasptop10" target="_blank">tryhackme.com - OWASP Top 10 (Server side security) <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                                <li><a href="https://app.hackthebox.com/challenges/245" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a> - Web - Gunship (?) </li>
                                <li>If these are too easy, check out the other web challenges at <a href="https://app.hackthebox.com/" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Web hacking <br /> 
                            <i>Burp Suite Special</i>
                        </td>
                        <td>N/A</td>
                        <td>
                            <a href="https://utwente-nl.zoom.us/rec/share/8rS76rCAiE2uVXBtQCd_IBnLlQrI91tg7-lSpY-1pPJW0gbf93gZJz9tYoe_C8cu.eoezIqvlCrEj3YkW" target="_blank">Recording <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: MF.My90=) <br>
                            <a href="http://ths.eemcs.utwente.nl:3000/fInQFZi9Th6M-cQKP542-A" target="_blank">Writeup <i class="fa fa-external-link" aria-hidden="true"></i></a>
                        </td>
                        <td>
                            <ul>
                                <li><a href="https://app.hackthebox.com/machines/Format" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a> - Machines - Format</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Binary Reverse Engineering</td>
                        <td>
                            <a href="/media/resources/slides/workshop_re_2023.pdf" target="_blank">Slides<i class="fa fa-external-link" aria-hidden="true"></i></a><br />
                            <a href="/media/resources/slides/workshop_re.pdf" target="_blank">Slides<i class="fa fa-external-link" aria-hidden="true"></i></a>
                        </td>
                        <td>
                            <a href="https://vimeo.com/867854036" target="_blank">Recording (2023) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: tC2}wA#6)<br>

                            <a href="https://utwente-nl.zoom.us/rec/share/f3AhsoT6dZUyN2ex5Tb7CAIan0Gl1Lwu0NaqQTgfLijWEFd8X5IVNucupLSudpi1.IdIAh71zACCWqDsE" target="_blank">Recording (2021 Part 1) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: $4@M@y3V)<br>
                            <a href="https://utwente-nl.zoom.us/rec/share/0cYy9o075V9zcKCK2h5wsus2ZLa7l8HPyx3Rj9tKBE9JrneqVyzl0PeHgz02a4Xk.t7XDslzAFC45NZ_3" target="_blank">Recording (2021 Part 2) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: jgvXgi#5)
                        </td>
                        <td>
                            <ul>
                                <li><a href="https://app.hackthebox.com/challenges/26" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a> - Reversing - Impossible Password </li>
                                <li><a href="https://app.hackthebox.com/challenges/121" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a> - Reversing - Exatlon </li>
                                <li><a href="https://crackmes.one" target="_blank">crackmes.one <i class="fa fa-external-link" aria-hidden="true"></i> - User-submitted Reversing Challenges </a></li>
                                <li><a href="/media/resources/challenges/workshop2020.zip" target="_blank"> Challenges for 2020 reverse engineering workshop <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                                <li>If these are too easy, check out the other reversing challenges at <a href="https://app.hackthebox.com/" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Binary Reverse Engineering <br /> 
                            <i>Ghidra Special</i>
                        </td>
                        <td>
                            <a href="/media/resources/slides/workshop_ghidra_2023.pdf" target="_blank">Slides<i class="fa fa-external-link" aria-hidden="true"></i></a><br />
                            <a href="/media/resources/slides/workshop_ghidra.pdf" target="_blank">Slides<i class="fa fa-external-link" aria-hidden="true"></i></a>
                        </td>
                        <td>
                            <a href="https://utwente-nl.zoom.us/rec/share/yRr9bGoG-QPDUzTmMEyj9BYnLosuf3HM8QI3k1RLlV_JlksgsmVYj04eh_PHKsoc.JqjKrdQk_bwTlEE1" target="_blank">Recording (2023) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: #.z0#wL9) <br />
                            <a href="https://utwente-nl.zoom.us/rec/play/QGuN6M3XKQ5sDmaoHFqI6cQH_GG8FaJ4TS8XOsdlgQjn-X3K1ueR2Z4IK1tIHUJLiIvyC7GZCyfbClCp.BxEperVljAEwIqHS" target="_blank">Recording (2022) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: 6VS?F9Db) 
                        </td>
                        <td>
                            <ul>
                                <li><a href="https://ths.eemcs.utwente.nl/challenges" target="_blank">THS <i class="fa fa-external-link" aria-hidden="true"></i></a> - Reversing - Command And Control</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Cryptography <br />
                            <i>RSA</i>
                        </td>
                        <td><a href="/media/resources/slides/workshop_crypto.pdf" target="_blank">Slides <i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td>
                            <a href="/media/resources/recordings/THS_crypto_1_recording.mp4" target="_blank">Recording (Part 1) <i class="fa fa-external-link" aria-hidden="true"></i></a><br>
                            <a href="https://utwente-nl.zoom.us/rec/share/w-GZ-8yF_93vU8ck0jeuFILXA19JEX5EP6Uqj3h4UHfukPRNftIhKuag5bQfJnkU.moBvUL45x4qB-8Zj" target="_blank">Recording (Part 2) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: 7e@7rRyg)
                        </td>
                        <td><ul>
                            <li>See "RSA challenge X" on this website</li>
                        </ul></td>
                    </tr>
                    <tr>
                        <td>
                            Forensics<br />
                            <i>OSINT Special</i>
                        </td>
                        <td><a href="/media/resources/slides/workshop_osint.pdf" target="_blank">Slides <i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td><a href="https://utwente.yuja.com/V/Video?v=738581&node=3623328&a=109834542" target="_blank">Recording<i class="fa fa-external-link" aria-hidden="true"></i></a><br></td>
                        <td>
                            <ul>
                                <li><a href="https://sourcing.games/">sourcing.games <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Forensics</td>
                        <td><a href="/media/resources/slides/workshop_forensics.pdf" target="_blank">Slides <i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td>
                            <a href="https://utwente-nl.zoom.us/rec/share/Hg0BUJ4Dc6AxypJu9qHXwdGymPrGf89frmhEGRaREn1P2QsKEnKPwlSw6UArXGJy._8zikEPTD4bL86Fn" target="_blank">Recording <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: 20Q.PL16)
                        </td>
                        <td><ul>
                            <li><b>Forensics:</b><ul>
                                <li> <a href="https://play.picoctf.org/practice/challenge/186?category=4&page=1" target="_blank">picoctf.org <i class="fa fa-external-link" aria-hidden="true"></i></a> - Forensics - Information</li>
                                <li> <a href="https://play.picoctf.org/practice/challenge/44?category=4&page=1" target="_blank">picoctf.org <i class="fa fa-external-link" aria-hidden="true"></i></a> - Forensics - Glory of the Garden</li>
                                <li> <a href="https://play.picoctf.org/practice/challenge/30?category=4&page=2" target="_blank">picoctf.org <i class="fa fa-external-link" aria-hidden="true"></i></a> - Forensics - Shark on wire 1</li>
                                <li> <a href="https://play.picoctf.org/practice/challenge/129?category=4&page=1" target="_blank">picoctf.org <i class="fa fa-external-link" aria-hidden="true"></i></a> - Forensics - Matryoshka doll</li>
                                <li> <a href="https://play.picoctf.org/practice/challenge/130?category=4&page=1" target="_blank">picoctf.org <i class="fa fa-external-link" aria-hidden="true"></i></a> - Forensics - MacroHard WeakEdge</li>
                                <li> <a href="https://ths.eemcs.utwente.nl/challenges" target="_blank">ths.eemcs.utwente.nl <i class="fa fa-external-link" aria-hidden="true"></i></a> - Forensics - Covert Channels </li>
                                <li> <a href="https://github.com/stuxnet999/MemLabs" target="_blank">MemLabs <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                            </ul></li>
                            <li><b>OSINT:</b><ul>
                                <li><a href="https://app.hackthebox.com/challenges/83" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a> - OSINT - Infiltration</li>
                            </ul></li>
                            <li><b>Steganography:</b><ul>
                                <li> <a href="https://ths.eemcs.utwente.nl/challenges">ths.eemcs.utwente.nl <i class="fa fa-external-link" aria-hidden="true"></i></a> - Forensics - Logo Safety Brigade </li>
                                <li> <a href="https://app.hackthebox.com/" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a> - Steganography - Forest</li>
                                <li> <a href="https://app.hackthebox.com/" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a> - Steganography - Digital Cube</li>
                            </ul></li>
                            <li>If these challenges are to easy, check out the other forensics challenges at <a href="https://picoctf.org" target="_blank">picoctf.org <i class="fa fa-external-link" aria-hidden="true"></i></a><br> and the forensics & OSINT challenges at <a href="https://app.hackthebox.com/" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a> </li>
                        </ul></td>
                    </tr>
                    <tr>
                        <td>Hacking live machines (boxes)</td>
                        <td><a href="/media/resources/slides/workshop_boxes.pdf" target="_blank">Slides <i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td>
                            <a href="https://utwente-nl.zoom.us/rec/share/Lt9_PZngLkBA7Uf67CTUl62nit2lLgoy0TmoxNzAnAo4vLvOeCw6B8Ys3je5VuJ7.1gdkhYP04dylneDu" target="_blank">Recording (Part 1) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: !6wEtkrH) <br>
                            <a href="https://utwente-nl.zoom.us/rec/share/UXD656dOeAwtlnU320NxLR7-PkVgDQ6UsgUYnR1ilYox7zSswo1zS9BO0uXlK_Bf.WA7gzMVE0eLFsXyg" target="_blank">Recording (Part 2) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: *F4rmSJe)
                        </td>
                        <td><ul>
                            <li> <a href="https://tryhackme.com/room/picklerick" target="_blank">tryhackme.com - Pickle Rick (demo) <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                            <li> <a href="https://tryhackme.com/room/basicpentestingjt" target="_blank">tryhackme.com - Basic Pentesting <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                            <li> <a href="https://tryhackme.com/room/owasptop10" target="_blank">tryhackme.com - OWASP Top 10 <i class="fa fa-external-link" aria-hidden="true"></i></a></li>
                            <li> For the more advanced boxes, see <a href="https://app.hackthebox.com/" target="_blank">hackthebox.com <i class="fa fa-external-link" aria-hidden="true"></i></a> - Machines</li>
                        </ul></td>
                    </tr>
                    <tr>
                        <td>Pwning</td>
                        <td><a href="/media/resources/slides/workshop_pwn.pdf" target="_blank">Slides <i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td>
                            <a href="https://utwente-nl.zoom.us/rec/share/SsutzYONXxXlURH4v2DgqoVZPNBJfFd30gnXLcyUWjFm5b6uH5c15QR_jjdzkt2h.mFsFCtIp0f0rZCyj" target="_blank">Recording (Part 1) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: d4&mqE@F) <br>
                            <a href="https://utwente-nl.zoom.us/rec/share/nwm63NUMLCQTtT7I4FonmpaNbeXV6CCiWLcrHdXkPMF_RDHEbusFAf4TrRgevXs1.m42OIOz-HZpqqBWa" target="_blank">Recording (Part 2) <i class="fa fa-external-link" aria-hidden="true"></i></a> (Passcode: f4+.ziq4)
                        </td>
                        <td><ul>
                            <li> <a href="https://ths.eemcs.utwente.nl/challenges">ths.eemcs.utwente.nl <i class="fa fa-external-link" aria-hidden="true"></i></a> - Pwn - Challenge 0 </li>
                            <li> <a href="https://ths.eemcs.utwente.nl/challenges">ths.eemcs.utwente.nl <i class="fa fa-external-link" aria-hidden="true"></i></a> - Pwn - Challenge 1 </li>
                            <li> <a href="https://ths.eemcs.utwente.nl/challenges">ths.eemcs.utwente.nl <i class="fa fa-external-link" aria-hidden="true"></i></a> - Pwn - Challenge 2 </li>
                            <li> <a href="https://ths.eemcs.utwente.nl/challenges">ths.eemcs.utwente.nl <i class="fa fa-external-link" aria-hidden="true"></i></a> - Pwn - Challenge 3 </li>
                            <li> <a href="https://ths.eemcs.utwente.nl/challenges">ths.eemcs.utwente.nl <i class="fa fa-external-link" aria-hidden="true"></i></a> - Pwn - Challenge 4 </li>
                        </ul></td>
                    </tr>
                    <tr>
                        <td>Pwn introduction 2023</td>
                        <td>N/A</td>
                        <td>
                            <a href="https://utwente.yuja.com/V/Video?v=620015&node=3013155&a=30991634&autoplay=1" target="_blank">Recording<i class="fa fa-external-link" aria-hidden="true"></i></a><br>
                        </td>
                        <td><ul>
                            <li> <a href="https://ths.eemcs.utwente.nl/challenges">ths.eemcs.utwente.nl <i class="fa fa-external-link" aria-hidden="true"></i></a> - Pwn - Malware shop </li>
                            <li> <a href="https://ths.eemcs.utwente.nl/challenges">ths.eemcs.utwente.nl <i class="fa fa-external-link" aria-hidden="true"></i></a> - Pwn - 1-2-pwn </li>
                        </ul></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
// Import components
// import [component name] from './[component name].vue'

// Export app
export default {
  name: 'ResourceWorkshops',

  // Set components
  components: {
    //   [component name],
  },

  methods: {
      // No methods yet
  },

  mounted(){
  },
}
</script>

<style>

</style>
