<template>
    <section id="logout">
        <div class="row">
            <div class="col-md-12 justify-content-center" v-if="this.logoutStatus === responseOptions.Pending">
                <div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>
            </div>
            <div class="col-md-12 justify-content-center" v-if="this.logoutStatus === responseOptions.Success">
                <h3>Logged out!</h3>
                <router-link :to="{ name: 'login' }">Click here to login again.</router-link>
            </div>
            <div class="col-md-12 justify-content-center" v-if="this.logoutStatus === responseOptions.Failed">
                <h3>Something seems to have gone wrong, please try again!</h3>
            </div>
        </div>
    </section>
</template>

<script>
import { api } from '../../axios';
import { responseOptions } from '../../store/constants';
import { mapActions } from 'vuex';
// Import components
// import [component name] from './[component name].vue';

// Export app
export default {
    name: 'LogoutMain',

    // Declare data used in login form
    data() {
        return {
            responseOptions: responseOptions,
            logoutStatus: responseOptions.Pending,
        }
    },

    // Set components
    components: {
        // [component name],
    },

    methods: {
        /**
         * Map actions from store
         */
        ...mapActions([
            'unloadUser',
        ]),

        /**
         * Send a logout request to the logout endpoint: `/api/homepage/authentication/logout`
         */
        async logout() {
            // Send the logout request
            let response = await api.post("/api/homepage/authentication/logout");

            console.log(response);
            
            // If there are errors, log them to the console
            if (response.data.status === "failed") {
                console.log(response.data.errors);
                // Set logout status to failed and return
                this.logoutStatus = responseOptions.Failed;
                return;
            }

            // Logged out succesfully, reset user object in state
            this.unloadUser();

            // Set logout status to success
            this.logoutStatus = responseOptions.Success;
        }
    },

    // Change height to auto
    mounted(){
        document.body.style.height = "auto";
        this.logout();
    },

    computed: {
        // No computed attributes
    }
}
</script>


