<template>
    <section id="reset">
        <h3>Reset Password</h3>
        <div class="row justify-content-center">
            <!-- Show form if password change has not been completed successfully yet-->
            <div class="col-md-12" v-if="!changeSuccessful">

                <p v-if="errors['other'].length">
                    <b>Please correct the following error(s):</b>
                    <ul>
                        <li v-for="error in errors['other']" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>
                
                <p>Please enter (and confirm) your new password.</p>
                <form action="" method="post" @submit="formSubmit">
                    <table>
                        <!-- Password -->
                        <tr>
                            <td><label for="id_password1">Password:</label></td>
                            <td><input type="password" name="password1" v-model="password1" autocomplete="new-password" required="" id="id_password1"></td>
                            <td>
                                <span class="fa"          v-if="fieldState['password1'] === FieldState.Empty"  >                           </span>
                                <span class="fa fa-check" v-if="fieldState['password1'] === FieldState.Valid"  >                           </span>
                                <span class="fa fa-times" v-if="fieldState['password1'] === FieldState.Invalid"> {{ errors['password1'] }} </span>
                            </td>
                        </tr>

                        <!-- Password Confirmation -->
                        <tr>
                            <td><label for="id_password2">Password confirmation:</label></td>
                            <td><input type="password" name="password2" v-model="password2" autocomplete="new-password" required="" id="id_password2"></td>
                            <td>
                                <span class="fa"          v-if="fieldState['password2'] === FieldState.Empty"  >                           </span>
                                <span class="fa fa-check" v-if="fieldState['password2'] === FieldState.Valid"  >                           </span>
                                <span class="fa fa-times" v-if="fieldState['password2'] === FieldState.Invalid"> {{ errors['password2'] }} </span>
                            </td>
                        </tr>
                        <!-- Password rules -->
                        <tr>
                            <td colspan="2" class="form-text">
                                <ul>
                                    <li>Your password must contain at least 8 characters.</li>
                                    <li>Your password cannot be too similar to your other personal information.</li>
                                    <li>Your password cannot be a commonly used password.</li>
                                    <li>Your password cannot be entirely numeric.</li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                    <button type="submit" class="btn btn-primary btn-lg" v-if="!loadingRequest">Change my password</button>
                    <button type="submit" class="btn btn-primary btn-lg" disabled="" v-else><span class="spinner-border spinner-border-sm"></span></button>
                </form>
            </div>

            <!-- If password change request has been completed successfully, show success message -->
            <div class="col-md-12" v-else>
                <h3>The password has been changed!</h3>
                <p><router-link :to="{ name: 'login' }">log in again?</router-link></p>
            </div>
        </div>
    </section>
</template>

<script>
import { api } from '../../axios'
import { FieldState } from '../../store/constants';
// Import components
// import [component name] from './[component name].vue';

// Export app
export default {
    name: 'PasswordChangeRequest',

    props: ['uidb64', 'token'],

    // Set components
    components: {
        // [component name],
    },

    // Declare data used in login form
    data() {
        return {
            FieldState: FieldState,
            errors: {
                "password1": null,
                "password2": null,
                "other"    : [],
            },
            fieldState: {
                "password1": FieldState.Empty,
                "password2": FieldState.Empty,
            },
            password1: null,
            password2: null,
            changeSuccessful: false,
            loadingRequest: false
        }
    },

    // Define function that watch the username, email, and passwords, such that we can update the status
    // messages when the user changes those fields
    watch: {
        /**
         * Watch the first password field, and check whether it is valid
         */
        password1: function(val) {
            // Test if field is empty
            if (val === "") {
                this.fieldState["password1"] = FieldState.Empty;
                this.errors["password1"] = "";
                return;
            }

            // Test if input has length >= 8
            if (val.length < 8) {
                this.fieldState["password1"] = FieldState.Invalid;
                this.errors["password1"] = "Password too short";
                return;
            }

            // Test whether field is not entirely numeric
            if (RegExp("^[0-9]+$").test(val)) {
                this.fieldState["password1"] = FieldState.Invalid;
                this.errors["password1"] = "Password cannot be entirely numeric";
                return;
            }

            // Field must be valid now
            this.fieldState["password1"] = FieldState.Valid;
            this.errors["password1"] = "";
        },

        /**
         * Watch the password confirmation field, and check whether it is valid
         */
        password2: function(val) {
            // Test if field is empty
            if (val === "") {
                this.fieldState["password2"] = FieldState.Empty;
                this.errors["password2"] = "";
                return;
            }

            // Test if input is same to first password
            if (val !== this.password1) {
                this.fieldState["password2"] = FieldState.Invalid;
                this.errors["password2"] = "Passwords are different";
                return;
            }

            // Field must be valid now
            this.fieldState["password2"] = FieldState.Valid;
            this.errors["password2"] = "";
        },
    },

    methods: {
        /**
         * Prevent the default action of the form, and send a POST request to authenticate
         * the user
         */
        async formSubmit(e) {
            e.preventDefault();

            this.errors['other'] = [];

            // Check if password field is valid
            if (this.fieldState['password1'] !== FieldState.Valid) {
                this.errors['other'].push("Please fix your password before submitting the form");
            }

            // Check if password confirmation field is valid
            if (this.fieldState['password2'] !== FieldState.Valid) {
                this.errors['other'].push("Please fix your password confirmation before submitting the form");
            }

            // If we have errors, return and let the user fix them
            if (this.errors['other'].length > 0) {
                console.log("We have errors, namely:", this.errors['other']);
                return;
            }

            // Set loading request status to true
            this.loadingRequest = true;

            // If we don't have errors, attempt to send a POST request for password change
            api.post(`/api/homepage/authentication/change/${this.uidb64}/${this.token}`, new URLSearchParams({
                password1: this.password1,
                password2: this.password2,
            })).then((response) => {
                // Set loading request status to false
                this.loadingRequest = false;
                
                // Check the response status
                // If there is an error, display the error and exit
                if (response.data.status === "failed") {
                    this.errors["other"].push("Something went wrong, please try again");
                    return;
                }

                // If our request was successful, display the success message
                this.changeSuccessful = true;
            }).catch((error) => {
                console.log(error);
            });
        }
    },

    // Change height to auto
    mounted(){
        document.body.style.height = "auto";
    },

    computed: {
        // No computed attributes
    }
}
</script>



<style scoped media="screen">
  .fa-check{
    color:green;
  }
  .fa-exclamation{
    color:orange;
  }
  .fa-times{
    color:red;
  }
  input{
      width: 100%;
  }
  .fa{
      padding-left: 8px;
      padding-right: 8px;
  }
</style>
