<template lang="html">
    <div class="row justify-content-center">
        <div class="col-lg-10 col-md-12">
            <!-- Header -->
            <h3 class="no-margin">Filter</h3>
            <hr>

            <!-- Search highscores -->
            <div class="row">
                <input id="searchHighscores" type="text" class="form-control filter-search" name="search" placeholder="Search highscores" v-on:keyup="search($event)">
            </div>

            <!-- Show staff highscores -->
            <div class="row form-check form-switch margin-top-sm">
                <label class="form-check-label filter-label" for="filterStaff">INCLUDE STAFF</label>
                <input id="filterStaff" class="form-check-input" type="checkbox" name="filterStaff" value="filterStaff" @change="toggleShowStaff($event)">
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'HighscoreFilter',

    methods: {
        /**
         * Search for a value using the target string.
         */
        search(event) {
            // Set search to new value
            this.$store.dispatch('searchHighscores', event.target.value);
        },

        /**
         * Toggle show staff highscores
         */
        toggleShowStaff(event) {
            // Set show staff highscore
            this.$store.commit(
                'setHighscoreShowStaff',
                event.target.checked,
            );
        },
    },
}
</script>

<style lang="css" scoped>
    .no-margin {
        margin: 0px;
    }

    .margin-top-sm {
        margin-top: 10px;
    }

    .filter-label {
        font-weight: 700;
        font-size: 16px;
    }

    .filter-search {
        font-size: 16px;
        width: 100%;
    }
</style>
